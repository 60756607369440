@import "variable.scss";
.vertical-center {
  min-height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
  /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

.container {
  max-width: 100% !important;
}

body {
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;

  // background-color: #eee !important;
  /* padding-top: 102px; */
}
.listing_wrap {
  background-color: #eee !important;
}

/*Breadcrumb*/

.breadcrumb {
  // padding: 5px;
  padding-top: 0px;
  padding-bottom: 5px;
  // background: $white;
  border-bottom: 1px solid #f2f2f2;
  clear: both;
  margin: 0;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 56px;
    // padding-top: 4px;
    li {
      font-family: "Roboto", sans-serif;
      float: left;

      padding: 8px 16px;
      position: relative;
      color: #21598a;
      font-weight: 500;
      font-size: 15px;
      a {
        padding: 0;
        position: relative;
        color: #343c42;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}

.breadcrumb ul li::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 17px;
  right: -7px;
  top: 5px;
  background: url(/icons/next.png) 1px 4px no-repeat;
}
// .breadcrumb ul li::before {
//   content: "";
//   position: absolute;
//   width: 10px;
//   height: 17px;
//   right: -7px;
//   top: 10px;
//   background: url(/icons/next.png) 1px 4px no-repeat;
// }
// .breadcrumb ul li a::before {
//   content: "";
//   position: absolute;
//   width: 10px;
//   height: 17px;
//   left: -7px;
//   top: 2px;
//   background: url(/icons/next.png) 1px 4px no-repeat;
// }
// .breadcrumb ul li:nth-last-child(1)::before{
//   content: "";
//   position: absolute;
//   width: 10px;
//   height: 17px;
//   left: 10px;
//   top: 10px;
//   background: url(/icons/next.png) 1px 4px no-repeat;
// }
.breadcrumb ul li:last-child::after {
  display: none;
}

.breadcrumb ul li:first-child a:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  left: -45px;
  top: -7px;
  // padding: 12px;
  background: #f1f1f1 url(/icons/previous.png) 50% 50% no-repeat;
  border-radius: 4px;
}
.breadcrumb ul li:first-child :hover {
  opacity: 0.5;
}

// .breadcrumb ul li:first-child a:before :hover{
//   -moz-box-shadow: 0 0 10px #ccc;
// -webkit-box-shadow: 0 0 10px #ccc;
// box-shadow: 0 0 10px #ccc;
// }

// .breadcrumb ul li:first-child a:before :hover {
//   background: red url(/icons/previous.png);
// }

// .breadcrumb ul li:first-child a span{
//  font-size: 30px;
//  position: absolute;
//  left: -13px;
//  top: -11px;
// }

.data_block a {
  color: #000;
}
.back {
  background: #f1f1f1;
  padding: 0 !important;
}

.back a {
  display: block;
  text-indent: -999999px;
  width: 40px;
  height: 40px;
}

.subtitle {
  font-size: 24px;
  color: #517bcc;
  padding-top: 30px;
  padding-bottom: 15px;
}

.companyform {
  padding-top: 40px;
  margin-left: 4%;
  width: 60%;
}

.chkox {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 10px 15px;
  max-width: 100%;
  background-color: #fff;
}

.chkox input[type="checkbox"] {
  float: right;
}

.chkox .form-check {
  padding-left: 0;
}

.form-check-label {
  padding-left: 10 !important;
}
.user_search_tab {
  // background-color: #0d6efd;
  padding-left: 0px;
}
// .user_search_tab select{
//   margin-top: 16px;
// }
.user_search_tab a {
  margin-right: 16px;
  margin-top: -3px;
}
/*Modal*/

.modal-title.h4 {
  color: #747474;
  font-weight: 400;
  font-size: 24px;
}

.modal-header {
  border-bottom: 0 !important;
  padding: 30px 58px 10px 40px;
}

.modal-footer {
  border-top: 0 !important;
  padding-right: 40px;
  padding-left: 40px !important;
  display: block;
  text-align: right;
  padding-top: 0;
}

.error_LW {
  p {
    color: #cf5a5a;
    text-align: center;
    font-size: 14px;
    border: 2px dashed #cf5a5a;
    padding: 14px;
    background-color: #ffe6e6;
  }
}
.aging_loading {
  background-color: #fff5e3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  border-radius: 4px;
  border: 1px dashed #e97a15;
}
.display_none {
  display: none;
}
.aging_loading p {
  color: #e97a15;
  text-align: left;
  font-size: 14px;
  padding: 14px;
}
.error_msgb {
  background-color: #ffe6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  border-radius: 4px;
  border: 1px dashed #cf5a5a;
}
.error_msgb p {
  color: #cf5a5a;
  text-align: left;
  font-size: 14px;
  padding: 14px 14px 0px 14px;
}
// .error_msgb{
//   background-color: #FFE6E6;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-top: 24px;
//   border-radius: 4px;
//   border: 1px dashed #CF5A5A;
// }
// .error_msgb p{
//   color: #CF5A5A  ;
//   text-align: left;
//   font-size: 14px;
//   padding:14px 14px 0px 14px;
// }
/*Navigation*/
.header {
  min-height: 120px;
  background: $white;
}
.date_color {
  // text-align: center;
  padding: 5px 10px;
  padding-left: 5px;
  // display: inline-block;
  font-family: "Trebuchet MS";
  color: gray;
  // background-color: whitesmoke;
  // margin: 5px;
  // margin-left: 30px;

  // width: fit-content;
  font-size: 12px;
}
.date_color:hover {
  color: #21598a;
  // color: white;
  border-radius: none;
}
.header_wrap {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 1000;
  background: $white;
  min-height: 82px;
}
.footer_wrap {
  width: 100%;
  left: 0;
  z-index: 1000;
  background: $white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.logo_wrap {
  margin: 0 0 10px;
}

.main_nav {
  background: $primary;
  padding: 0;
  ul {
    margin: 0;
    padding: 0;
    li a {
      color: $white;
      padding: 18px 20px;
      font-size: 11px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      &:focus {
        color: $white;
        text-decoration: none;
      }
    }
    li.active a,
    li a:hover {
      color: $white;
      background: #0f385b;
      text-decoration: none;
      text-decoration: none;
    }
  }
}
.address_details p {
  display: inline-block;
}
.lang_btn {
  background: $primary;
  padding: 2px 10px;
  border-radius: 3px;
  border: 0px;
  color: #fff;
}

/* .logo_wrap {
  margin-right: 30px;
  padding-top: 20px;
} */

/* .main_nav {
  position: absolute;
  top: -30px;
  right: -14px;
  background: #e7e7e7;
  width: calc(100% + 30px);
} */

.logo_image {
  /* width: 80%; */
  /* padding: 5px 0px 20px 10px;
   */
  padding: 9px 0 0;
  max-width: 230px;
}

/* .navbar {
  justify-content: flex-start;
  padding-bottom: 0;
} */

/* .navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 25px;
  text-align: right;
} */

/* .top_headernav {
  position: absolute;
  top: -10px;
  right: 15px;
  z-index: 1;
} */

/* .top_headernav {
  top: 30px;
} */

.navbar ul li {
  list-style: none;
  display: inline-block;
  &.hello_login {
    display: none;
  }
}

/*User navigation*/
.noComments {
  display: flex;
  justify-content: center;
  min-height: 30px;
  align-self: center;
  padding: 50px;
}

.user_nav {
  text-align: right !important;
  padding-top: 15px;
}

.user_nav .hello,
.user_nav .dropdown {
  display: inline-block;
}

.user_nav .hello {
  font-weight: 500;
  font-size: 16px;
  font-family: "Roboto", sans-serif !important;
  color: #555b6e;
  padding-right: 10px;
}

.user_nav .hello:hover {
  text-decoration: none;
}

.status_header {
  position: absolute;
  top: 1em;
  left: 70%;
  color: red;
}

.company_wrap {
  background: #fafafa;
  position: relative;
  padding: 16px;
  margin-left: 1%;
  margin-right: 1%;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  margin-top: 15px;
  margin-bottom: -16px;
}

.edit_company {
  position: absolute;
  top: 16px;
  right: 16px;
}

.add_company {
  position: absolute;
  top: -45px;
  right: 0px;
}
h1 {
  font-size: 35px !important;
  color: #517bcc !important;
  font-weight: 500 !important;
  font-family: "Roboto", sans-serif !important;
}

.welcome,
.subheading {
  font-size: 25px;
  color: #747474;
}

.gmsg {
  font-size: 14px;
  color: #747474;
  margin-bottom: 25px;
}

.form_wrap label {
  display: none;
}

.form_wrap button {
  width: 100%;
  margin-top: 15px;
}

.center_nav {
  text-align: center;
}

.logowrap {
  margin-bottom: 10px;
}

.msgwrap {
  background: #e6eeff;
  color: #517bcc;
  font-size: 18px;
  padding: 10px 15px;
  margin-bottom: 30px;
  border-radius: 4px;
}

h2 {
  color: #406dcd;
  font-size: 26px;
}

h4 {
  font-size: 24px;
  color: #465168;
}
.h4 {
  font-size: 24px;
  color: #517bcc;
}
// .react-datepicker-wrapper {
//   width: 230px !important;
// }
/**Label and forms*/

label {
  font-size: 14px;
}

input[type="text"],
input[type="number"],
input[type="date"] {
  font-size: 14px;
  line-height: 24px;
  background: #fff;
}

.search {
  background: #fff;
  padding: 12px;
}

.search input[type="text"] {
  max-width: 507px;
}

.search label {
  display: none;
}

.srch_status {
  display: inline-block;
  margin-right: 12px;
  width: auto;
}

.srch_status .rs-picker-toggle-wrapper {
  padding: 2px 0 3px;
  width: auto !important;
  max-width: 100%;
  min-width: 165px;
}

/*Buttons*/
// .react-datepicker button,
// .btn1 {
//   display: inline-block;
//   font-weight: 400;
//   line-height: 1.5;
//   color: #212529;
//   text-align: center;
//   text-decoration: none;
//   vertical-align: middle;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   background-color: transparent;
//   border: 1px solid transparent;
//   padding: 0.375rem 0.75rem;
//   font-size: 1rem;
//   border-radius: 0.25rem;
//   -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
//     -webkit-box-shadow 0.15s ease-in-out;
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
//     -webkit-box-shadow 0.15s ease-in-out;
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
//     box-shadow 0.15s ease-in-out;
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
//     box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
// }

.react-datepicker button {
  margin: 15px 5px;
}

.btn_save button {
  font-size: 20px;
}

// .react-datepicker button {
//   color: #0d6efd;
//   border: none;
//   display: inline-block;
//   // border-color: #0d6efd;
//   margin: 5px 5px;
// }

// .react-datepicker__month-container {
//   padding: 10px;
//   font-size: 14px;
// }

.viewmore {
  background: #e6eeff;
  font-family: Roboto;
  font-style: Medium;
  font-size: 14px;
  color: #406dcd;
  padding: 2px 10px;
  margin-bottom: 10px;
}

.accept {
  background: #f0fff3;
  font-family: Roboto;
  font-style: Medium;
  font-size: 14px;
  color: #388f46;
  padding: 4px 10px;
  position: relative;
  top: -5px;
}

.addbtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: 43px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  background: #21598a;
  color: #ffffff;
  border-radius: 4px;
}

.order_new_report {
  // background: #21598A url(/icons/order_new_report.png) 11px 9px no-repeat;
  background-size: 19px;
  padding: 7px 20px 8px 10px;
  width: 190px;
}

.order_new_report > img {
  margin-right: 4px;
}
.resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
}

.resizer:hover,
.resizing {
  border-right: 1px solid #c1c1c1;
}

#credit-reports-table > thead th {
  position: relative;
  border-right: 1px solid #c1c1c1;
}

#credit-reports-table > thead th:last-child {
  border-right: none;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.order_new_report:focus,
.order_new_report:hover {
  // background: #184165 url(/icons/order_new_report.png) 11px 9px no-repeat;
  color: #fff;
  transition: 0.2s;
}

.order_report_right.edit_form {
  max-width: 864px;
  margin: auto;
  padding: 30px 15px;
}

.resetPassBtn {
  padding: 8px 20px;
  background: #406dcd;
  color: #fff;
  font-size: 14px;
}

.changePassBtn {
  padding: 8px 20px;
  background: #406dcd;
  color: #fff;
  font-size: 14px;
}

.btnedit {
  background: #fff;
  border: 1px solid #406dcd;
  color: #406dcd;
}

.btnedit1 {
  background: #fff;
  border: 2px solid #406dcd;
  color: #406dcd;
}

.btnedit:disabled {
  border: 1px solid #d0d0d0;
  color: #d0d0d0;
}

.btnedit1:disabled {
  border: 2px solid #d0d0d0;
  color: #d0d0d0;
}

.btnedit:hover,
.btnedit1:hover {
  background: #406dcd;
  color: #fff;
  transition: 0.2s;
}

.btn_light {
  background: #fff;
  border: 1px solid #e0e0e0;
  color: #406dcd;
  padding: 7px 20px;
}

.btnremove {
  background: #fff;
  border: 1px solid #cf5a5a;
  color: #cf5a5a;
}

.btndelete {
  background: #ffe6e6;
  /* border: 1px solid #CF5A5A; */
  color: #cf5a5a;
  padding: 1px 11px;
}

.legal_upload {
  font-size: 16px;
  padding: 12px 32px 12px 32px;
  color: #fff;
  background-size: 20px;
  background-color: #517bcc;
}

.bottom_gap {
  margin-bottom: 24px;
}

.inactivebtn {
  background: #cf5a5a;
  font-family: Roboto;
  /* font-style: Medium; */
  font-size: 14px;
  color: #fff;
  padding: 1px 6px;
}

.canceled,
.download,
.incorporate,
.bank,
.bank_error,
.incorporate_download,
.bank_download,
.legal,
.others,
.scoring,
.supplier_download,
.supplier,
.new,
.processing,
.partially_completed,
.pending,
.completed,
.upload,
.error,
.need_action {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.new,
.pending,
.processing {
  margin-right: 10px;
}
.incorporate.warning {
  color: black;
}

// .canceled:hover,
// /* .download:hover, */
// .incorporate:hover,
// .bank:hover,
// .bank_error:hover,
// /* .incorporate_download:hover, */
// /* .bank_download:hover, */
// .legal:hover,
// /*
// .supplier:hover, */
// .new:hover,
// .processing:hover,
// .partially_completed:hover,
// .pending:hover,
// .completed:hover,
// /* .upload:hover, */
// .error:hover,
// .need_action:hover {
//   // color: #fff;
// }
// .supplier_download{
//   // background: #e6eeff;
//   // color:black;
//   background: #5383ec;
//   color:white;
// }
// .supplier_download:hover{
//   background: #5383ec;
//   color:white;
// }

.status1 .or_processing {
  /* url(/icons/processing.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}

.status1 .or_processing:hover {
  /* url(/icons/processing_h.png) 95% 4px no-repeat */
  background: #f1f1f1;
}

.status2 .or_processing {
  /* url(/icons/processing.png) 95% 4px no-repeat */
  background: #fff5e3;
  color: #e97a15;
}

.status2 .or_processing:hover {
  /* url(/icons/processing_h.png) 95% 4px no-repeat */
  background: #fff5e3;
  color: #e97a15;
}

.status3 .or_processing {
  /* url(/icons/processing.png) 95% 4px no-repeat */
  background: #ffe6e6;
  color: #cf5a5a;
}

.status3 .or_processing:hover {
  /* url(/icons/processing_h.png) 95% 4px no-repeat */
  background: #ffe6e6;
  color: #cf5a5a;
}

.status5 .or_processing {
  /* url(/icons/processing.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}

.status5 .or_processing:hover {
  /* url(/icons/processing_h.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}

.status6 .or_processing {
  /* url(/icons/processing.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}

.status6 .or_processing:hover {
  /* url(/icons/processing_h.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}
.status7 .or_processing {
  /* url(/icons/processing.png) 95% 4px no-repeat */
  background: #7cb9ee;
  color: #fff;
}

.status7 .or_processing:hover {
  /* url(/icons/processing_h.png) 95% 4px no-repeat */
  background: #7cb9ee;
  color: #fff;
}
.status8 .or_processing {
  /* url(/icons/processing.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}

.status8 .or_processing:hover {
  /* url(/icons/processing_h.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}
.status1 .or_success {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}

.status2 .or_success {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #fff5e3;
  color: #e97a15;
}

.status3 .or_success {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #ffe6e6;
  color: #cf5a5a;
}

.status5 .or_success {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat; */
  background: #e6ffeb;
  color: #388f46;
}

.status6 .or_success {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}
.status7 .or_success {
  /* url(/icons/processing.png) 95% 4px no-repeat */
  background: #7cb9ee;
  color: #fff;
}
.status8 .or_success {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat; */
  background: #e6ffeb;
  color: #388f46;
}

.status1 .or_error {
  /* url(/icons/need_action_and_error.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}

.status1 .or_error:hover {
  /* url(/icons/need_action_and_error_h.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}

.status2 .or_error {
  /* url(/icons/need_action_and_error.png) 95% 4px no-repeat */
  background: #fff5e3;
  color: #e97a15;
}

.status2 .or_error:hover {
  /* url(/icons/need_action_and_error_h.png) 95% 4px no-repeat */
  background: #fff5e3;
  color: #e97a15;
}

.status3 .or_need_action {
  /* url(/icons/need_action_and_error.png) 95% 4px no-repeat */
  background: #ffe6e6;
  color: #cf5a5a;
}

.status3 .or_need_action:hover {
  /* url(/icons/need_action_and_error_h.png) 95% 4px no-repeat */
  background: #ffe6e6;
  color: #cf5a5a;
}

.status4 .or_error {
  /* url(/icons/need_action_and_error.png) 95% 4px no-repeat */
  background: #ffe6e6;
  color: #cf5a5a;
}

.status4 .or_error:hover {
  /* url(/icons/need_action_and_error_h.png) 95% 4px no-repeat */
  background: #ffe6e6;
  color: #cf5a5a;
}

.status5 .or_error {
  /* url(/icons/need_action_and_error.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}

.status5 .or_error:hover {
  /* url(/icons/need_action_and_error_h.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}

.status6 .or_error {
  /* url(/icons/need_action_and_error.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}

.status6 .or_error:hover {
  /* url(/icons/need_action_and_error_h.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}
.status7 .or_error {
  /* url(/icons/processing.png) 95% 4px no-repeat */
  background: #7cb9ee;
  color: #fff;
}
.status8 .or_error {
  /* url(/icons/need_action_and_error.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}

.status8 .or_error:hover {
  /* url(/icons/need_action_and_error_h.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}

.status1 .or_pending {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}

.status2 .or_pending {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #fff5e3;
  color: #e97a15;
}

.status3 .or_pending {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #ffe6e6;
  color: #cf5a5a;
}

.status5 .or_pending {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}

.status6 .or_pending {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}
.status7 .or_pending {
  /* url(/icons/processing.png) 95% 4px no-repeat */
  background: #7cb9ee;
  color: #fff;
}
.status8 .or_pending {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}
.status1 .or_cancelled {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}

.status2 .or_cancelled {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #fff5e3;
  color: #e97a15;
}

.status3 .or_cancelled {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #ffe6e6;
  color: #cf5a5a;
}

.status5 .or_cancelled {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}

.status6 .or_cancelled {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}
.status7 .or_cancelled {
  /* url(/icons/processing.png) 95% 4px no-repeat */
  background: #7cb9ee;
  color: #fff;
}
.status8 .or_cancelled {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}

.status1 .or_new {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}

.status2 .or_new {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #fff5e3;
  color: #e97a15;
}

.status3 .or_new {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #ffe6e6;
  color: #cf5a5a;
}

.status5 .or_new {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}

.status6 .or_new {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}
.status7 .or_new {
  /* url(/icons/processing.png) 95% 4px no-repeat */
  background: #7cb9ee;
  color: #fff;
}

.status8 .or_new {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}
.status1 .or_partially_completed {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}

.status2 .or_partially_completed {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #fff5e3;
  color: #e97a15;
}

.status3 .or_partially_completed {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #ffe6e6;
  color: #cf5a5a;
}

.status5 .or_partially_completed {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}

.status6 .or_partially_completed {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #f1f1f1;
  color: #747474;
}
.status7 .or_partially_completed {
  /* url(/icons/processing.png) 95% 4px no-repeat */
  // background: #e6ffeb;
  // background: radial-gradient(#f8e2b9 20%, #e6ffeb);
  background: linear-gradient(to right, #e6ffeb, #f8e2b9);
  color: #388f46;
}

.status8 .or_partially_completed {
  /* url(/icons/download-report-green.png) 95% 4px no-repeat */
  background: #e6ffeb;
  color: #388f46;
}
/* .or_processing {
   background: #E6FFEB url(/icons/download-report-green.png) 95% 4px no-repeat; 
  color: #388F46;
} */

/* .or_success {
  background: #E6FFEB url(/icons/download-report-green.png) 95% 4px no-repeat;
  color: #388F46;
} */

/* .or_error {
  background: #E6FFEB url(/icons/download-report-green.png) 95% 4px no-repeat;
  color: #388F46;
} */

/* .or_pending {
  background: #E6FFEB url(/icons/download-report-green.png) 95% 4px no-repeat;
  color: #388F46;
} */

/* .or_cancelled {
  background: #E6FFEB url(/icons/download-report-green.png) 95% 4px no-repeat;
  color: #388F46;
}

.or_new {
  background: #E6FFEB url(/icons/download-report-green.png) 95% 4px no-repeat;
  color: #388F46;
} */

/*-----*/

.download {
  line-height: 21px;
  /* url(/icons/download-report-white.png) 95% 4px no-repeat */
  background: #40b753;
  padding: 2px 10px 2px 10px;
  color: #fff;
}

.download:disabled {
  background: #f1f1f1;
  /* url(/icons/download-report.png) 95% 4px no-repeat */
  padding: 2px 10px 2px 10px;
  color: #c4c4c4;
}

.download_grey,
.ag_download,
.ag_delete,
.upload,
.new,
.pending,
.processing,
.completed,
.canceled,
.error,
.partially_completed,
.need_action {
  color: #fff;
  padding: 2px 8px;
  cursor: default !important;
}

.download_grey {
  /* color: #CC9326; */
  /* background: #FFFAF0;
  background: #FFFAF0 url(/icons/report-processing.png) 95% 4px no-repeat; */
  background: #e6eeff;
  color: #517bcc;
  /* padding: 2px 10px 2px 10px; */
  text-decoration: none;
  font-size: small;
}

.ag_download {
  background: #e6eeff;
  color: #517bcc;
}

.ag_delete {
  background: #e6eeff;
  color: #cf5a5a;
}

.incorporate {
  /* color: #CC9326; */
  /* background: #FFFAF0; */
  /* background: #FFFAF0 url(/icons/report-processing.png) 95% 4px no-repeat; */
  padding: 2px 34px 2px 10px;
}

/* .incorporate_download {
  color: #388F46;
  background: #E6FFEB;
  background: #E6FFEB url(/icons/download-report-green.png) 95% 4px no-repeat;
  padding: 2px 34px 2px 10px;
} */

.bank {
  color: black;
}

.bank_warning {
  color: #cf5a5a;
  // background: #ffe6e6 url(/icons/download-report-green.png) 95% 4px no-repeat;
  background: #ffe6e6 95% 4px no-repeat;
}

.bank_download {
  color: #388f46;
  /* background: #E6FFEB; */
  // background: #e6ffeb url(/icons/download-report-green.png) 95% 4px no-repeat;
  background: #e6ffeb 95% 4px no-repeat;
}

.legal {
  color: #388f46;
  /* background: #E6FFEB; */
  // background: #e6ffeb url(/icons/download-report-green.png) 95% 4px no-repeat;
  background: #e6ffeb 95% 4px no-repeat;
}
.others {
  color: #388f46;
  background: #e6ffeb;
  // background: #e6ffeb url(/icons/download-report-green.png) 95% 4px no-repeat;
}

.scoring {
  color: #388f46;
  background: #e6ffeb;
  // background: #e6ffeb url(/icons/download-report-green.png) 95% 4px no-repeat;
}

.supplier {
  color: #388f46;
  /* background: #E6FFEB; */
  // background: #e6ffeb url(/icons/download-report-green.png) 95% 4px no-repeat;
  background: #e6ffeb 95% 4px no-repeat;
}

.report-status {
  height: 24px;
  padding: 2px 10px 29px 10px;
  text-align: center;
  margin: 0 4px;
}

.order-status {
  color: black;
  padding: 0px;
  margin: 0px;
  height: 24px;
}

.upload {
  color: #fff;
  background: #50c262;
}

.upload.success {
  background: #50c262;
}

.upload.error {
  background: #ff0000;
}

.upload.info {
  background: #ffaa04;
}

.new,
.upload.processing {
  background: #2875cf;
}

.pending {
  background: #747474;
}

.processing {
  background: #ffa700;
  // background: #7cb9ee;
  // color: #fff;
}

.completed {
  background: #40b753;
}

.canceled {
  background: #000;
  color: #fff;
}

.need_action {
  background: #cf5a5a;
}
.partially_completed {
  background: #7cb9ee;
  color: #fff;
}
.error {
  background: #cf5a5a !important;
  border: 0 !important;
  padding: 2px 8px !important;
  width: auto !important;
  margin: 0 !important;
}

.downarrow {
  border: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: #e6eeff url(/icons/down-arrow.png) 50% 50% no-repeat;
  background-size: 12px;
}

.dn_arrow_wrap {
  float: right !important;
  margin-right: 0 !important;
  position: relative;
  margin-top: 2px;
}

.dn_arrow_wrap button {
  margin-left: 10px;
}

.dn_arrow_wrap:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-left: 1px solid #f2f2f2;
}

.btn-primary {
  background: #517bcc;
}

.upArrow {
  background: #e6eeff url(/icons/down-arrow.png) 50% 50% no-repeat;
  transform: rotate(180deg);
}

.clearB {
  clear: both;
}

.imagewrap {
  float: left;
  width: 95px;
  height: 95px;
  overflow: hidden;
  margin-right: 15px;
  margin-bottom: 15px;
  text-align: center;
  vertical-align: middle;
}

.logoImage {
  height: auto;
  width: 100%;
}
.user_table_user tr th {
  font-size: 14px;
}
.user_table_user tr th:nth-child(2) {
  width: 110px;
}
.rightdata {
  margin-left: 110px;
  padding-top: 15px;
}

.popupContent {
  padding: 0 25px;
}

.cemail {
  color: black;
}

.cphone {
  color: black;
}

.subcompany_wrap {
  position: relative;
}

.caddress {
  margin-bottom: 30px;
}

.caddress p {
  margin-bottom: 3px;
}

.colbox2 span {
  width: 200px;
  display: inline-block;
}

.sub-company {
  font-size: 24px;
  font-weight: 400;
  padding-top: 35px;
  margin-bottom: 16px;
}

// .subcompany_wrap .company_wrap {
//   // margin-bottom: 16px;
// }

// .cemail,
// .cphone,
// .caddress {
//   padding-left: 25px;
//   position: relative;
// }

// .cemail::before,
// .cphone::before,
// .caddress::before {
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 6px;
//   width: 15px;
//   height: 15px;
// }

// .cemail::before {
//   background: url(/icons/Mail.png) no-repeat;
// }

// .cphone::before {
//   background: url(/icons/Phone.png) no-repeat;
// }

// .caddress::before {
//   background: url(/icons/Location.png) no-repeat;
// }

/* company form*/

.companyform .row {
  margin-bottom: 16px;
}

.popupform {
  padding: 3px 27px;
}
.popupform_modal {
  padding: 3px 0px 50px 120px;
  display: grid;
}

/*Grid*/

.listing1 table {
  border-collapse: initial;
}

.listing > table {
  border-collapse: initial;
  border-spacing: 0 15px !important;
}

.listing1 th,
.listing th {
  color: #4d4d4d;
  font-size: 16px;
  background: #fbfbfb;
  padding: 17px 10px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  font-weight: 500;
}

.listing1 th span,
.listing th span {
  display: inline-block;
  position: relative;
  padding: 0 10px 0 0;
}

.listing1 th.sorted span:before,
.listing1 th.sorted span:after,
.listing th.sorted span:before,
.listing th.sorted span:after {
  content: "";
  position: absolute;
  right: 0;
  width: 5px;
  height: 5px;

  background-size: 5px !important;
}

//.listing.credit_header th.sorted span:before,
.listing.credit_header th.sorted span:after {
  content: "";
  position: absolute;
  right: -10px;
  width: 15px;
  height: 15px;

  background-size: 10px !important;
  top: 5px;
  background: url(/images/down_arrow.svg) no-repeat;
}

.listing.credit_header th.sorted.ascending span:after {
  content: "";
  position: absolute;
  right: -10px;
  width: 15px;
  height: 15px;

  background-size: 10px !important;
  top: 5px;
  background: url(/images/up_arrow.svg) no-repeat;
}

.listing.credit_header th.sorted:hover {
  cursor: pointer;
}

.listing1 th.sorted span:before,
.listing th.sorted span:before {
  background: url(/icons/sort-u.png) no-repeat;
  top: 8px;
}

.listing1 th.sorted span:after,
.listing th.sorted span:after {
  background: url(/icons/sort-d.png) no-repeat;
  top: 14px;
}

.listing1 th.sorted.descending span:before,
.listing th.sorted.descending span:before {
  background: url(/icons/sort-u.png) no-repeat;
  top: 10px;
}

.listing.credit_header th.sorted.descending span::before,
.listing.credit_header th.sorted span:before {
  display: none;
}

.listing1 th.sorted.descending span::after,
.listing th.sorted.descending span::after {
  display: none;
}

.listing1 th.sorted.ascending span:after,
.listing th.sorted.ascending span:after {
  background: url(/icons/sort-d.png) no-repeat;
  top: 10px;
}

.listing1 th.sorted.ascending span:before,
.listing th.sorted.ascending span:before {
  display: none;
}

.listing2 > table {
  padding: 0;
}

/*   */
.listing > table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.listing > table > tbody > tr {
  border-radius: 10px;
  /* box-shadow: 0px 0px 2px 2px #fafafa; */
}

.listing > table > tbody > tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.listing > table > tbody > tr:first-child {
  border-radius: 0px;
  box-shadow: none;
}

.listing > table > tbody > tr > td:first-child {
  border-radius: 10px 0 0 10px;
  border-left: 1px solid #f1f1f1;
}

.listing > table > tbody > tr > td:last-child {
  border-radius: 0 10px 10px 0;
  border-right: 1px solid #f1f1f1;
}

.listing > table > tbody > .cr_status_row td {
  vertical-align: top;
  padding: 12px 8px 12px;
}

.listing1 td,
.listing td {
  color: #323232;
  font-size: 16px;
  background: #fff;
  padding: 16px 10px;
  border-bottom: 1px solid #f2f2f2;
  font-family: "Roboto", sans-serif;
}

.db_listing td {
  padding: 5px 10px 3px !important;
}

.database_actions_buttons_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  button {
    white-space: nowrap;
  }
}

.listing > table > tbody > tr > td {
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  padding: 5px;
}

.listing > table > tbody > tr td td {
  border-top: 0px solid #f1f1f1 !important;
  border-bottom: 0px solid #f1f1f1 !important;
  padding: 5px;
}

.listing.subrow {
  padding: 8px;
  border: solid 1px white;
}
.expand_animation {
  transition: 0.5s;
  animation: dropdownrow 0.2s normal forwards ease-in;
}
@keyframes dropdownrow {
  from {
    transform: translateY(-20px);
    opacity: 0.5;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
.mr-3 {
  margin-right: 3px;
}
.text-bold {
  font-weight: bold !important;
}

.close_animation {
  transition: 0.5s;
  animation: dropdownclose 0.2s normal forwards ease-in;
}

@keyframes dropdownclose {
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(-60px);
    opacity: 0.5;
  }
}

.listing .table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: #fff;
  color: #323232;
  border-top: 0px;
}

.listing tr:hover {
  background-color: rgba(31, 31, 109, 0.5);
}

.listing .table > :not(:first-child) {
  border-top: 0px solid currentColor;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #f2f2f2;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: rgba(255, 255, 255, 0.05);
  color: #323232;
}

.buttongroups > div {
  float: left;
  padding-top: 3px;
}

.role {
  display: inline-block;
  max-width: 160px;
  margin-right: 20px;
}

.status {
  display: inline-block;
  /* max-width: 180px; */
  width: 100%;
  margin-right: 20px;
}

.select_date {
  display: inline-block;
  width: initial;
  margin-right: 20px;
}

.select_date .rs-picker-default .rs-picker-toggle.rs-btn {
  height: 41px;
}

.select_date input[type="text"] {
  width: 100%;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.f1 {
  display: inline-block;
  max-width: 200px;
  margin-right: 20px;
}

/**Company tab Nav*/

.company_nav,
.company_nav_inner {
  border-bottom: 1px solid #dadada;
}

.company_nav,
company_nav_inner {
  margin: 5px 0px 0px 0px;
}

.company_blocks {
  padding-bottom: 30px;
}

.company_nav_inner .nav-link {
  padding: 16px 25px;
  font-weight: 400;
  font-size: 14px;
  font-family: "Roboto";
  font-style: normal;
}
// .company_nav li a:hover{
//   background-color: #21598a;
//   color: white;
//   text-decoration: none;
// }
.company_nav li a,
.company_nav_inner li a {
  text-decoration: none;
  margin: 0px 10px;
  font-size: 14px;
  color: #969696;
}
.company_nav li,
.company_nav_inner li {
  // border: 1px solid #fff;
  border-bottom: 0px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
// .company_nav li:hover{
//   transform: translateY(-5px);
//   transition: 0.6s;
// }
.company_nav li.active,
.company_nav_inner li.active {
  // border: 1px solid #e6eeff;
  border-bottom: 0px;
  // border-radius: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 4px solid #21598a;
  position: relative;
  bottom: -2.5px;
  a {
    color: #21598a !important;
    font-weight: bold;
    font-family: "Roboto";
    font-style: normal;
  }
}

.category_link a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  position: static;
  height: 40px;
  left: 0px;
  top: 80px;
  text-decoration: none;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  background: #21598a;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.inner_search {
  padding: 15px;
  margin-bottom: 0px !important;
}

.admin_moreinfo button {
  margin-left: 16px;
}

.more_info_wrap table,
.more_info_wrap table td {
  border: 0;
}

.info_price,
.info_unit,
.info_manual {
  font-size: 14px !important;
}

.info_price strong,
.info_unit strong,
.info_manual strong {
  font-size: 16px !important;
}

.info_manual {
  width: 100px;
  color: #517bcc !important;
  width: 120px;
  vertical-align: top;
}

textarea {
  border: 1px solid #517bcc;
}

.modal-footer.flexstart {
  justify-content: flex-start;
}

.info_price {
  width: 150px;
  color: #3b4a68 !important;
}

.info_unit {
  width: 120px;
  color: #517bcc !important;
}

h5 {
  font-size: 18px;
  color: #747474;
}

h6 {
  font-size: 16px;
  color: #3b4a68;
}

p {
  color: #323232;
  font-size: 16px;
}

p.warning {
  color: #cf5a5a;
}

p small {
  color: #3b4a68;
  font-size: 10px;
}

.comments_item_wrap {
  padding: 16px 16px 0;
  height: 200px;
  width: 100%;
  overflow-y: auto;
}

.comments_items {
  background: #fbfbfb;
  padding: 15px;
  border-bottom: 1px solid #f2f2f2;
}

.comments_items:last-child {
  border-bottom: 0;
}

.comments_wrapper {
  padding-top: 15px;
}

.comments_wrapper {
  background: #fbfbfb;
  /* border: 1px solid #F2F2F2; */
  border-radius: 4px;
}

.more_info_wrap {
  background: #fbfbfb;
  border-radius: 4px;
}

.comments_wrapper input[type="text"] {
  border: 2px solid #517bcc;
  line-height: 22px;
}

.comments_wrapper td {
  background: #fbfbfb;
}

.comments_indent {
  padding: 15px 0 0 !important;
  border-bottom: 0;
}

.comments_indent td {
  border-bottom: 0;
}

.comments_indent h5 {
  margin-bottom: 10px;
  color: #747474;
  font-weight: 500;
  padding-left: 15px;
}

.post_status_wrap {
  width: 120px;
}

.post_add_wrap {
  width: 60px;
}

.delete_comment {
  float: right;
}

.activities_indent {
  padding: 0px !important;
  padding-top: 20px !important;
}

.tab-content {
  max-height: 300px;
  overflow-y: auto;
}

.activities_wrapper .nav-tabs .nav-link {
  color: #747474;
}

.activities_wrapper {
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 10px 10px 30px 15px;
  background: #fbfbfb;
}

.activities_wrapper .nav-tabs .nav-item.show .nav-linkF {
  font-size: 12px;
  color: #747474;
  font-weight: 400;
}

.activities_wrapper .nav-tabs {
  border: 0;
  margin-bottom: 15px;
  /* border-bottom: 2px solid #517BCC; */
}

.activities_wrapper .nav-tabs .nav-link.active {
  border: 0;
  border-bottom: 2px solid #517bcc;
  /* font-size: 14px; */
  color: #517bcc;
  background: none;
  /* border-radius: 4px; */
}

.activities_wrapper .cr_activity_list {
  list-style: none;
  padding: 0;
  margin: 0;
  /* list-style: none;
  padding-left: 17px;
  padding-right: 17px; */
  /* border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6; */
}

.activities_wrapper .cr_activity_list .cr_activity_item {
  /* padding-bottom: 15px;
  border-bottom: 1px solid #dee2e6; */
  padding-top: 10px;
  /* padding-left: 1.6em; */
  /* text-indent: -.7em; */
  position: relative;
  padding-left: 2.2em;
}

.activities_wrapper .cr_activity_list .cr_activity_item::before {
  /* padding-bottom: 15px; */
  /* border-bottom: 0px solid #dee2e6; */
  /* #517BCC */
  /* content: "• ";
  color: #517BCC; */
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background: #517bcc;
  border-radius: 10px;
  left: 9px;
  top: 18px;
  z-index: 1;
}

.activities_wrapper .cr_activity_list .cr_activity_item::after {
  content: "";
  position: absolute;
  border-left: 1px dashed #dcdcdc;
  width: 1px;
  height: 100%;
  top: 19px;
  left: 12px;
}

.activities_wrapper .cr_activity_list .cr_activity_item:last-child:after {
  display: none;
}

.cr_activity_item_container {
  font-weight: 400;
  color: #000;
  font-size: 16px;
}

.cr_activity_date {
  font-size: 10px;
  color: #c4c4c4;
  font-weight: 400;
}

/*My account*/

.myaccount {
  color: black;
}

.acc_title {
  color: #517bcc;
  font-size: 20px;
}

.login_name {
  font-size: 24px;
  color: #3b4a68;
}

.acc_email,
.acc_phone {
  font-size: 18px;
  color: #3b4a68;
}

.sidebarwrap_inner {
  position: relative;
  min-height: 75vh;
  border: 1px solid #e5e5e5;
  /* padding: 15px; */
}

.sidebarwrap {
  position: relative;
  height: 85vh;
  overflow: auto;
  border: 1px solid #e5e5e5;
  padding: 15px;
}

.sidebarwrap .logoutbtn {
  position: absolute;
  bottom: 15px;
  left: 15px;
  padding: 12px 24px;
}

.back_button_bank {
  cursor: pointer;
}

.buttonAlliancePrimary {
  color: #ffffff;
  background: #21598a;
  border-radius: 4px;
  font-size: 16px;
  padding: 9px 15px;
  font-family: "Roboto";
  font-style: normal;
  width: max-content;
  border: none;
}
.buttonAlliancePrimary:disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: rgb(209, 206, 206);
  color: white;
}
.buttonAlliancePrimary:hover {
  background-color: #1a4469;
}
.buttonAlliancePrimary_Disabled {
  pointer-events: none;
  background-color: rgb(209, 206, 206);
}
.buttonAlliancePrimary_Disabled:hover {
  cursor: not-allowed;
  pointer-events: none;
}
.buttonAllianceSecondary {
  color: #21598a;
  border-radius: 4px;
  border: 2px solid #21598a;
  background: #ffffff;
  padding: 7px 15px;
  font-size: 16px;
  font-family: "Roboto";
  font-style: normal;
  width: max-content;
  font-weight: 500;
}
.buttonAllianceSecondary:hover {
  background: #e6eeff;
  color: #21598a;
}
.buttonAllianceSecondary:disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: rgb(61, 59, 59);
  border: 2px solid rgb(209, 206, 206);
}
.buttonAllianceSecondary_Disabled {
  pointer-events: none;
  border-color: rgb(209, 206, 206);
}
.buttonAllianceSecondary_Disabled:hover {
  cursor: not-allowed;
  pointer-events: none;
}

.buttonAllianceSecondary_watchlist {
  color: #21598a;
  border-radius: 4px;
  border: 2px solid #21598a;
  background: #ffffff;
  padding: 7px 15px;
  font-size: 16px;
  font-family: "Roboto";
  font-style: normal;
  width: max-content;
  font-weight: 500;
  pointer-events: none;
  cursor: not-allowed;
}
// .accountAdmin .sidebarwrap, .accountUser .sidebarwrap {
//   // height : 100%;
// }

.alignBottom {
  position: absolute;
  bottom: 0px;
  left: 20%;
}

.ac_left {
  float: left;
}

.ac_right {
  float: right;
  padding-bottom: 10px;
}

/* for the dropdown month selector in aging */
.react-datepicker-popper {
  background-color: white;
  border: solid 1px #e5e8eb;
  padding: 4px;
  border-radius: 3px;
}

.error {
  background-color: #ffe6e6;
  border: dashed 1px #cf5a5a;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
}

/*Order new reports*/
.page_wrap {
  overflow-x: hidden;
  background: #fff;
}

.order_reportwrap {
  background: #fff;
  position: relative;
}

.order_reportwrap::before {
  content: "";
  position: absolute;
  left: 100%;
  background: #ffffff;
  height: 100%;
  width: 100%;
  z-index: 999;
}

.order_report_right {
  padding-top: 15px;
  padding-bottom: 50px;
  padding-left: 20px;
  min-height: 80vh;
}

.order_report_left {
  background: #fafafa;
  position: relative;
  z-index: 1;
}

.order_report_left.whitebg {
  background: #fff;
}

.report_steps {
  padding: 20px 0 0px 80px;
  position: fixed;
  left: 0;
  // z-index: 9;
}

.report_steps > div {
  padding-bottom: 80px;
}

.select_report {
  margin-bottom: 10px;
  /* height: 245px; */
  display: flex;
  /* height: 245px; */
}

.select_report > div {
  float: left;
  width: 200px;
  height: 200px;
  margin-right: 20px;
  position: relative;
  border-radius: 4px;
  /* overflow: hidden; */
  /* background: #EFEFEF; */
}

.select_report figure {
  text-align: center;
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.select_report caption {
  position: absolute;
  bottom: -50px;
  text-align: center;
  width: 100%;
  font-size: 18px;
  color: #517bcc;
}

.cr-time {
  margin-top: -4px;
  font-size: 12px;
}

.comment-header {
  display: inline-block;
  margin-right: 8px;
  color: #3b4a68;
}

.cr_status_row td {
  vertical-align: top;
}
// .cr_status_row td:nth-child(7){
//   // width: 10%;
// }
.cr_status_row td:nth-child(2) {
  font-size: 14px;
  // width: 15%;
}
.cr_status_row td:nth-child(1) {
  font-size: 14px;
  width: 120px;
}
.cr_status_row td:nth-child(3) {
  font-size: 14px;
  width: 100px;
}
.cr_status_row td:nth-child(4) {
  //width: 150px;
  font-size: 14px;
}
.text-align-center {
  text-align: center !important;
}
// /* Hide scrollbar for Chrome, Safari and Opera */
// body::-webkit-scrollbar {
//   display: none;
// }

// /* Hide scrollbar for IE, Edge and Firefox */
// body {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }
.cr_status_row td:nth-child(5) {
  width: 190px;
  font-size: 14px;
  padding: unset;
}
.cr_status_row td:nth-child(6) {
  min-width: 215px;
}
.external_links_popup {
  background-color: #fff;
  // width: 600px !important;
  min-width: 400px;
}
.report_upload_button {
  border-radius: 5px;
  padding: 3px 6px;
  color: white;
  background-color: #21598a;
  font-weight: 500;
  float: right;
  margin-bottom: 30px;
}
.report_upload_button_empty {
  border-radius: 5px;
  padding: 3px 6px;
  color: white;
  background-color: #158125;
  font-weight: 500;
  // float: right;
  margin-top: 10px;
}
.or_search {
  max-width: 400px;
  margin-bottom: 30px;
}

.or_subtitle,
.or_search label {
  color: #323232;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

// .or_subtitle {
//   width: 100%;
// }

.or_search input[type="text"] {
  background: url(/icons/Seach.png) 10px 10px no-repeat;
  padding-left: 35px;
}

.or_check_group {
  margin-bottom: 30px;
}

.or_check_group label {
  color: #747474;
  font-weight: 400;
  padding-right: 50px;
}

.or_select_all label {
  color: #747474;
  font-weight: 400;
}

.upload_cr_app {
  padding-top: 20px;
}

.upload_cr_app input[type="file"] {
  display: none;
}

.quick_order_wrap {
  border-top: 2px solid #f2f2f2;
  padding-top: 20px;
  color: #21598a;
}

.quick_order {
  border: 2px solid #21598a;
  padding: 11px 20px 11px 60px;
  margin-left: 25px;
  background: url(/icons/quick_order.png) 19px 13px no-repeat;
  color: #21598a;
  background-size: 27px;
  transition: 0.4s;
}
.quick_fix {
  margin-left: 500px;
}

.quick_order:hover {
  background: #21598a url(/icons/quick_order_h.png) 19px 13px no-repeat;
  background-size: 27px;
  color: #fff;
  transition: 0.4s;
}

.or_help {
  background: url(/icons/help.png) 0px 0px no-repeat;
  display: inline-block;
  width: 21px;
  height: 21px;
  margin-left: 20px;
  position: relative;
  top: 5px;
}

.order_report_right .h2 {
  color: General;
  font-size: 22px;
  font-weight: 500;
}

.step3_form {
  margin-bottom: 40px !important;
  margin-left: 3%;
  margin-right: 3%;
}

.step3_form label,
.step2_form label {
  font-size: 14px;
  font-weight: 500;
  color: #747474;
  margin-bottom: 5px;
}

.step2_form input[type="text"] {
  background: #f8f8f8;
  // border: 1px solid #dddddd;
}

.form-group {
  margin-bottom: 20px;
  margin-left: 24px;
}

.form_head {
  font-size: 24px;
  font-weight: 600;
  color: #555b6e;
}

.searchterm {
  font-size: 20px;
  margin: 30px 0px 10px;
}

.or_success_message {
  text-align: center;
  padding: 15px;
  padding-top: 40px;
  max-width: 600px;
  margin: auto;
}

.or_success_message p {
  text-align: center;
  font-size: 18px;
  color: #747474;
}

.imgblockmessage {
  display: block;
  /* max-width: 100%; */
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin: 0px;
  margin: auto;
  width: initial;
  max-width: 300px;
}

//  .credit-report,
// .database-report,
// .legal-watchlist,
// .groups,
// .companies,
// .legal-uploads,
// .aging {
//   background-size: 15px !important;
//   padding: 0px;
//   position: relative;

// }

// .database-report a,
// .legal-watchlist a,
// .groups a,
// .companies a,
// .legal-uploads a,
// .aging a {
//   // font-size: 12px;
//   // color: #333333;
//   padding:-1px;
// }

/* .credit-report.active:before,
.database-report.active:before,
.legal-watchlist.active:before,
.groups.active:before,
.companies.active:before,
.legal-uploads.active:before,
.aging.active:before {
  content: "";
  border-bottom: 3px solid #517BCC;
  position: absolute;
  bottom: -3px;
  left: 5%;
  width: 90%;
} */

.groups {
  background: url(/icons/groups_0.png) 12px 6px no-repeat;
}

.groups.active {
  background: url(/icons/groups.png) 12px 6px no-repeat;
}

/* .companies {
  background: url(/icons/companies_0.png) 12px 6px no-repeat;
}

.companies.active {
  background: url(/icons/companies.png) 12px 6px no-repeat;
} */

.legal-uploads {
  background: url(/icons/legal_upload_0.png) 12px 10px no-repeat;
  background-size: 17px !important;
}

.legal-uploads.active {
  background: url(/icons/legal_upload.png) 12px 10px no-repeat;
  background-size: 17px !important;
}

/* .credit-report {
  background: url(/icons/credit_reports_0.png) 12px 9px no-repeat;
}

.credit-report.active {
  background: url(/icons/credit_reports.png) 12px 9px no-repeat;
}

.credit-report.active a {
  color: #517BCC;
} */

/* .database-report {
  background: url(/icons/database_reports_0.png) 12px 10px no-repeat;
}

.database-report.active {
  background: url(/icons/database_reports.png) 12px 10px no-repeat;
}

.legal-watchlist {
  background: url(/icons/legal_watchlist_0.png) 12px 13px no-repeat;
}

.legal-watchlist.active {
  background: url(/icons/legal_watchlist.png) 12px 13px no-repeat;
}

.aging {
  background: url(/icons/help.png) 12px 10px no-repeat;
}

.aging.active {
  background: url(/icons/help.png) 12px 10px no-repeat;
} */

/* .aging {}
.aging.active {} */
/* .status-needsAction {}

.private_comment {
  color: lightGrey;
}

.delete_comment {
  margin-left: 15px;
  margin-bottom: -5px;
}

.status-cancelled {} */

.popup_title {
  font-size: 24px;
  font-weight: 500;
}

.lofin_form {
  min-height: 100vh;
  background: url(/img/alliance_bg.png) 50% 13% no-repeat;
  background-size: initial;
}

// .login_form form,
.login_form {
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* border: 3px solid green;  */
}

.login_wrap {
  max-width: 480px;
  width: 100%;
  margin: auto;
  padding: 50px 50px;
  -webkit-box-shadow: 0px 0px 18px 0px #b8b8b8;
  box-shadow: 0px 0px 18px 0px #b8b8b8;
  /* top: 50%;
  position: relative; */
  background: #ffffff;
  /* transform: translateY(13%); */
  /* -webkit-transform: translateY(-50%); */
  /* transform: translateY(-50%); */
  /* width: 100%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%);*/
}

.login_wrap input[type="password"],
.login_wrap input[type="text"] {
  font-size: 16px;
  color: #8a8a8a !important;
  padding: 8px 15px;
}

.btnlogin {
  font-weight: 500;
  font-size: 18px;
  padding: 8px 15px;
}

.fpass a {
  font-size: 18px;
  text-decoration: underline;
  color: #517bcc;
}

.fpass a:hover {
  text-decoration: none;
  transition: 0.5s;
}

.formContainer label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 8px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f8f8f8;
  opacity: 1;
}

.rs-picker-default,
.rs-picker-toggle {
  border-color: #ced4da !important;
  border: none;
  height: 38px;
  border-radius: 0.25rem;
}

#dropdownMenuButton1 {
  padding-right: 0;
  width: initial;
  padding-left: 0;
  margin-left: 17px;
  padding: 0;
}

#dropdownMenuButton1:hover {
  text-decoration: none;
}

#dropdownMenuButton1::after {
  display: none;
}
#tr_td {
  padding: 10px 8px 8px 8px;
}
#tr_td1 {
  margin: 0px;
  padding-top: 10px;
  font-size: 14px;
  padding-left: 15px;
}
.user_nav .dropdown-menu.show {
  display: block;
  padding: 0;
  width: 200px;
}

.user_nav .dropdown-menu li {
  padding: 7px 2px 11px 47px;
  font-family: "Roboto", sans-serif;
  text-align: left;
}

.user_nav .dropdown-menu li a {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #555b6e;
}

.aging_upload {
  background: url(/icons/aging.png) 15px 10px no-repeat;
  background-size: 16px;
}

.logout {
  background: url(/icons/log_out.png) 18px 13px no-repeat;
  background-size: 16px;
}

.logout a {
  color: #cf5a5a !important;
  cursor: pointer;
}

.account {
  background: url(/icons/account.png) 13px 10px no-repeat;
  background-size: 17px;
}

.groupnav {
  background: url(/icons/groups_0.png) 13px 10px no-repeat;
  background-size: 17px;
}

.order_fixed_nav {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.order_report_bottom {
  border-top: 1px solid #f2f2f2;
  padding-top: 12px;
  color: #517bcc;
  z-index: 9;
  padding-right: 25px;
  background: #fff;
  padding-bottom: 12px;
}

.vlline {
  position: relative;
  background: #f1f1f1;
}

.vlline:before {
  content: "";
  position: absolute;
  border-left: 1px solid #f1f1f1;
  height: 113px;
  top: -110px;
  left: 13px;
  z-index: -1;
}

.vlline.first:before {
  display: none;
}
.order-New-Report_stepBullet__G-0Nm::before {
  content: "";
  position: absolute;
  border-left: 1px solid #517bcc;
  height: 113px;
  top: -110px;
  left: 13px;
  z-index: -1;
}

.rs-picker-check-tree-menu {
  z-index: 5000 !important;
}

.rs-picker-select-menu {
  z-index: 5000 !important;
}

.modal_datepicker {
  padding-top: 20px;
  max-width: 400px;
}

.modal_datepicker .react-datepicker__tab-loop {
  position: relative;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.modal_datepicker label {
  padding-bottom: 10px;
}

.modal_datepicker .react-datepicker-wrapper input[type="text"] {
  display: block;
  width: 50%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  outline: none;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  font-size: 14px;
  line-height: 27px;
  background: #fff;
  padding-right: 20px;
  background: url(/icons/down-arrow.png) calc(100% - 12px) 50% no-repeat;
}

.legal_dd {
  border-bottom: 1px solid #f1f1f1;
  margin: 2%;
  margin-bottom: 0px;
}

.ddwatchlst {
  display: inline-block;
  padding-bottom: 15px;
}

.legal_bulkadd {
  // padding: 15px 0;
  margin: 1%;
  padding-top: 18px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.escTable {
  width: 100%;
}

.escRow {
  margin-bottom: 5px;
}

.escCell {
  padding: 5px;
}

.escBottomRow {
  border-top: solid 1px black;
}

.escDiv {
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  border-radius: 5px;
  border: 1px solid #eaeaea;
}

.escTable .even {
  background-color: #f6f6f6;
}

.escTable label,
.escTable td {
  color: #7a7a7a;
}

.escTable td {
  padding: 10px 20px;
}

.escTable label {
  padding-left: 15px;
}

.escTable td {
  background: none;
}

.external_wrapper {
  padding: 30px 96px;
  min-height: 100vh;
  background: #f0f7ff url(/img/alliance_bg.png) -70% -2% no-repeat;
}

.thankyoupage {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  .thanksHeading {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 40px;
    color: #388f46;
  }
  .thanksMessage {
    padding: 10px 70px;
    strong {
      font-weight: 500;
    }
  }
  p {
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .external_innerwrap {
    max-width: 638px;
  }
  .logo_image {
    max-width: 180px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.external_wrapper .logowrap {
  margin-bottom: 30px;
}

.external_wrapper .external_innerwrap {
  background-color: #fcfdff;
  border: 1px solid #ededed;
  padding: 35px 22px;
  border-radius: 5px;
}

.ext_title {
  font-size: 18px;
  font-weight: 500;
}

.ext_cms {
  margin-bottom: 30px;
}

.ext_cms p {
  font-size: 14px;
  line-height: 25px;
  word-break: break-word;
}

.ext_cms p b {
  font-size: 14px;
  font-weight: 500;
}

.acnumber {
  margin: 25px 0px 0px 30px;
  font-size: 16px;
  font-weight: 500;
}

.acnumber b {
  color: #406dcd;
}

// .external_form_wrap {
//   // max-width: 800px;
// }

.external_form_wrap label {
  margin-bottom: 5px;
  font-weight: 500;
  color: #555b6e;
}

.external_submit {
  background: #406dcd url(/icons/forward.png) 80% 50% no-repeat;
  padding: 11px 55px 11px 30px;
  margin-right: 15px;
}

.external_reject {
  padding: 11px 30px 11px 30px;
  margin-right: 15px;
  border: 2px solid #cf5a5a;
  color: #cf5a5a;
  background: $white;
}
.external_reject:hover {
  // border: 2px solid #cf5a5a;
  color: $white;
  background: #cf5a5a;
}
/* .r_status {
  width: 290px;
} */

.external_links_popup_body {
  width: 600px;
  background: #fff;
  border-radius: 4px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.popup_block {
  margin-bottom: 15px;
  clear: both;
}

.popup_block h5 {
  font-size: 16px;
  color: #747474;
  font-weight: 500;
  margin-bottom: 5px;
}

.popup_block:last-child {
  margin-bottom: 0;
}

.popup_block a {
  border: 1px solid #f0f0f0;
  padding-left: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 68px);
  margin-right: 5px;
  line-height: 41px;
  float: left;
  position: relative;
  width: 350px;
}

.popup_block a::before {
  content: "";
  position: absolute;
  display: inline-block;
  border-right: 1px solid #f0f0f0;
  height: 100%;
  background: url(/icons/link.png) 50% 50% no-repeat;
  width: 50px;
  left: 0;
  top: 0;
}

.popup_block button {
  display: inline;
}

.popup_block .ext_link {
  border: 1px solid #f0f0f0;
  position: relative;
  padding: 3px;
  border-radius: 4px;
  line-height: 49px;
  height: 51px;
  white-space: nowrap;
}

.pop_close {
  padding: 6px 10px;
  margin-top: -11px;
  margin-left: 2px;
}

.set_price {
  width: 280px;
  box-shadow: 0px 0px 6px 0px #f4f4f4;
  padding: 10px;
  clear: right;
}

.set_price label {
  float: left;
  width: 85px;
  color: #747474;
  font-size: 16px;
  line-height: 40px;
}

.set_price input {
  float: left;
  width: 82px;
  margin-right: 10px;
}

.set_price button {
  float: left;
  width: 79px;
}

.set_manual {
  text-align: right;
  padding: 5px;
  background: #f4f4f4;
  color: #517bcc;
  margin-bottom: 5px;
  cursor: pointer;
  float: right;
  border-radius: 4px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #fbfbfb #fbfbfb #fbfbfb;
  /* isolation: isolate; */
}

.form-spacer {
  margin-bottom: 20px;
}

.report-is-quick-report {
  margin-top: -1em;
  position: absolute;
  font-size: 9px;
  color: #ff0000;
}

.report-is-credit-app {
  margin-top: -1em;
  position: absolute;
  font-size: 9px;
  color: #1e4aa2;
}

.highZIndex {
  z-index: 99999 !important;
}

.info_unit,
.info_price {
  vertical-align: top;
}

.info_unit {
  width: 180px;
}

figcaption {
  padding-top: 15px;
}

.selected_dbsearch {
  background-color: #21598a;
  padding: 5px 10px;
  color: white;
}

.notselected_dbsearch {
  background-color: white;
  align-items: normal;
  padding: 2px 10px;
  color: gray;
}

.selected_credit_search {
  background-color: #21598a;
  padding: 2px 10px;
  color: white;
  font-size: 10px;
}

.notselected_credit_search {
  background-color: #f2f2f2;
  align-items: normal;
  padding: 2px 10px;
  color: rgb(75, 75, 75);
  font-size: 10px;
}

.sup_new {
  background-color: #21598a;
  color: white;
  border-radius: 7px;
  padding: 2px 5px;
  font-weight: bold;
  font-size: 10px;
}
.sup_new_selected {
  background-color: white;
  color: #21598a;
  border-radius: 7px;
  padding: 2px 5px;
  font-weight: bold;
  font-size: 10px;
}

.humbergernav {
  display: none;
}

/*Plaid*/
.plaid_container {
  background: #929292;
  width: 100vw;
  height: 100vh;
}

.plaid_wrap {
  max-width: 672px;
  margin: 30px auto;
  padding: 40px 30px;
  border-radius: 10px;
  background: #fff;
  height: max-content;
}

.plaid-link button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  background: #406dcd !important;
  width: 100%;
}

iframe {
  border: 0;
  margin: auto;
  // max-width: 900px;
  display: block;
}
.notfound {
  text-align: center;
  padding: 150px 30px 0;
}
.h_ar {
  text-align: right;
  padding-top: 21px;
}
.creditbg {
  background-color: rgb(211, 211, 211);
  margin-left: 10px;
  margin-top: 20px;
}
.headcredit {
  background-color: rgb(226, 226, 226);
  margin-left: 10px;
  margin-top: 10px;
  padding: 14px;
}
.headcredit h5 {
  color: #000;
  font-weight: 500;
}
// .eventinfo{
//   background: #F6F6F6;

//  min-height: 10vh;
//   width: 1360px;
// }
.eventinfo {
  margin-left: 10px;
}

.eventinfo h5 {
  color: #000;
}
.container h5 {
  font-weight: 500;
}

h6 {
  color: #000;
}

.maincredit {
  background: #f6f6f6;
}
.infomain h5 {
  color: #000;
}
.eventinfo a {
  margin-left: 10px;
  color: rgb(117, 117, 238);
  text-decoration: none;
}
.fillbg {
  background-color: rgb(240, 239, 239);
  margin-left: 5px;
  margin-top: 20px;
  padding: 7px;
  border-radius: 5px;
}

.sidebar {
  height: 50px;

  width: 1250px;
  border-radius: 10px;
}

.box1 {
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: justify;
}

.box2 {
  text-align: justify;
}
.sidebar button {
  outline: none;
}

.dropbutton {
  background-color: rgb(211, 211, 211);
  border: none;
  margin-left: 10px;
  height: 50px;
  width: 1200;
}

.active-select-option {
  display: flex;
  margin-left: 10px;
  width: 100%;
  border: 1px solid grey;
  line-height: 1.8;
}
.subjectfull {
  background-color: #0d6efd;
}

#files {
  visibility: hidden;
}
.labelchoose {
  border: 1px solid rgb(179, 175, 175);
  padding: 8px;
  font-weight: 500;
  border-radius: 3px;
  background-color: rgb(233, 232, 232);
}
.labelchoosen {
  border: 1px solid rgb(179, 175, 175);
  padding: 8px;
  font-weight: 500;
  border-radius: 3px;
  background-color: white;
}
.event_title_padding {
  padding: 24px 0px 0px 10px;
}

.subject_file_input {
  width: 420px;
}

h5 {
  color: #000;
}
.navborder {
  border-bottom: 1px solid rgb(168, 167, 167);
}

.active-select-option {
  padding: 7px;
  height: 40px;
  margin-bottom: 15px;

  border: none;
}
.btnClass {
  border-radius: 4px;
  background-color: #cf5a5a;
  color: white;
  border: none;
}

.btnClass.clicked {
  background-color: #21598a;
  border-radius: 4px;
  color: white;
  border: none;
}

.coll-panel-btn:focus {
  outline: 0;
  box-shadow: none;
  min-width: auto;
  width: auto;
}

.coll-panel {
  padding: 0px;
  margin-bottom: 8px;
}

.maincreditdetails {
  background-color: #d4d4d4;
}

.submitbg {
  background-color: #000;
}
.fillcredithead {
  width: 1240px;
  height: 62px;
  background-color: #d4d4d4;
  display: flex;

  justify-content: space-between;

  padding: 16px;
}
.buttonsfill {
  display: flex;
  justify-content: space-between;
}
.fillcreditheadcont {
  background-color: white;
}

.expandsubj {
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
  color: #21598a;
}
.expandsubj_showmore {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 10px;
  color: #21598a;
  padding-left: 5px;
  padding-top: 0px;
  // right: 37.65%;
}
.fillcreditheadsub h6 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  padding-left: "30px !important";
  /* Text Dark Blue Gray */

  color: #343c42;
}
.fillcreditheadsub {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 12px 12px 12px;
  position: static;
  width: 100%;
  height: 58px;
  left: 0px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  padding-left: 24px !important;
}

.buttonfillcredit {
  display: flex;
  margin-right: 15px;
}
.buttonfillcredit p {
  margin-left: 25px;
}

.expandfill {
  margin-top: 2px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
  color: black;
  position: static;
  right: 37.65%;
}

.lastsub {
  width: 211px;
  height: 21pz;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 21.09px;
}
.headdetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 21.09px;
  color: #000;
}
.headdetails p {
  margin-right: 18px;
  // margin-top: 18px;
}
.eventinfo p {
  font-weight: 500;
  font-size: 18px;
}
.eventinfo span {
  color: #3371f2;
  font-weight: 400;
  font-size: 18px;
  margin-left: 6px;
}
.collapspacing {
  margin: 10px;
}

// ***************** groups ********************

.group_card {
  position: static;
  width: 99.5%;
  min-height: auto;
  left: 16px;
  top: 67px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 6px;
}

// .group_card_body1{
//   background-Color:white;

.view_more {
  background-color: #e6f1ff;
  color: #21598a;
  border: none;
  border-radius: 4px;
  padding: 2px 12px;
  font-weight: 450;
  font-size: 14px;
}
.delete_watchlist {
  color: #cf5a5a;
  background-color: #ffe6e6;
  border-radius: 4px;
  padding: 2px 12px;
  font-weight: 450;
  border: none;
  font-size: 14px;
}
.lw_companies_add {
  color: gray;
  font-weight: 400;
  span {
    color: #21598a;
    font-weight: 500;
    font-family: "Roboto";
  }
}
.breadcrumb_watchlist {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #f2f2f2;
  span:nth-child(1) {
    margin-left: 1%;
    padding-right: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #21598a;
  }
  &:hover {
    color: #040e16;
  }
  span:nth-child(2) {
    font-weight: 400;
    font-size: 14px;
    color: #21598a;
  }
  &:hover {
    color: #040e16;
  }
}
.companies_show {
  display: flex;
  flex-direction: column;
  float: right;
  margin-top: -30px;
}
.business_mail {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 5px;
  width: 100%;
  padding-left: 10px;
  background-color: #f6f6f6;
  outline-color: #21598a;
}
.watchlist_type {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 5px;
  width: 100%;
  padding-left: 10px;
  background-color: #f6f6f6;
  outline-color: #21598a;
  height: 40px;
}
.button_watchlist {
  border: none;
  color: #e6f1ff;
  padding-bottom: 5px;
  border-radius: 0.25em;
  cursor: pointer;
}

.remove_gutter_x {
  --bs-gutter-x: 0px;
}
.bank_options_style {
  color: gray;
}

.groups_card {
  background-color: white;
  line-height: "2rem";
}
.title_color {
  position: static;
  width: auto;
  height: 21px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  color: #323232;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.group_title {
  // line-height: 20px;
  background-color: white;
  border-bottom: 0px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}
.groups_card_header {
  border-top: 0px;
  border-bottom: 0px;
  line-height: 1rem;
  border-radius: 5px;
  margin: 3px;
  background-color: rgb(243, 239, 239);
}

.event_date {
  margin: 0px;
  padding: 5px;
}

.padding_style {
  padding: 15px;
}

.group_show_btn {
  border-radius: 4px;
  margin: 8px 8px;
  font-size: 16px;
  background-color: #21598a;
  color: white;
  padding: 10px;
  border: none;
}

.group_show_btn:hover {
  color: #ffffff;
  background: #1a4469;
}
// ***********event page/2*****************

.event_navbar {
  font-size: 20px;
  margin: 11px;
  line-height: 20px;
}
.event_groupcard {
  background-color: white;
  line-height: 1rem;
  font-size: 19px;
}

.event_status_ongoing {
  // margin-right: 3px;
  // color: white;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;

  position: static;

  height: 22px;
  left: 221px;
  top: 0px;
  margin-bottom: 10px;
  /* Attention Orange */

  background: #50c262;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}
.event_name_font {
  font-size: 20px;
  line-height: 3rem;
  margin-top: -1px;
}

.event_name_st {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.event_name_st p {
  position: static;
  height: 21px;
  left: 0px;
  top: 0px;
  padding-right: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 9px;
}
.fill_credit_ref_st {
  position: static;
  left: 1132px;
  top: 1.5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}

.event_card2 {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  padding: 4px;
  margin: 10px;
  height: 9rem;
}
.even_status_completed {
  color: white;
  background-color: #40b753;
}
.margin_right {
  margin-right: -127px;
}
.event_details_btn {
  min-width: 200px;
  margin-right: 135px;
}
.background-color {
  background-color: #f2f5f8;
}
.edit_event_btn {
  margin-right: 20px;
  margin-top: -2px;
}
.event_font {
  font-size: 16px;
}
.event_header_hight {
  line-height: 15px;
  display: flex;
}
.modal_styles {
  position: static;
  width: 420px;
  height: 40px;
  left: 0px;
  top: 22px;
}
.create_event_modal {
  width: 920px !important;
}
.create_event_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  position: static;

  left: 0px;
  top: 0px;
  color: #ffffff;

  background: #21598a;
  border-radius: 4px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
}

.date_time input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 100%;
  height: 40px;
  left: 0px;
  top: 22px;

  background: #ffffff;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 6px 0px;
}

.footer_event {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
}

.footer_event .date_pick {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px;
  width: 100%;
  position: static;
  height: 40px;
  left: 0px;
  top: 22px;
  background: #fffefe;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 4px;
}

.show_more_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  height: 43px;
  left: 16px;
  top: 91px;
  color: #ffffff;
  /* Alliance Blue */

  background: #21598a;
  border-radius: 4px;

  /* Inside auto layout */
  text-decoration: none;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 10px 0px;
  border: none;
}

.status_downloade_report {
  position: static;
  width: 305px;
  height: 19px;
  left: 536px;
  top: 8px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #000000;
  text-align: center;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.create_event_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  position: static;
  width: 145px;
  height: 43px;
  left: 1270px;
  top: 0px;

  /* Alliance Blue */

  background: #21598a;
  border-radius: 4px;
  border: none;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
}
.create_new_event_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  height: 43px;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
  color: #ffffff;
  border: none;
}
.full_event_name {
  font-size: 20px;

  line-height: 20px;
  padding-top: 10px;
  margin-top: 20px;
}

.event_title_span {
  cursor: pointer;
  width: 120px;
  height: 19px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #323232;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px;
  padding: 6px 8px 6px 0px;
}
.groups_bg {
  background-color: #e5e5e5;
}
.padding_left_arrow {
  padding-left: 10px;
}

// .dialog_esc{
//   // background-color: transparent;
// }
.error_form_validate {
  color: red;
  border: none;
  padding-top: 10px;
}
.error_msg {
  color: red;
}

.company-input-wrapper {
  display: flex;
  white-space: nowrap;
  position: relative;
}

.company-prefix {
  position: absolute;
  left: 4px;
  z-index: 5000;
  color: rgb(44, 44, 81);
  padding: 0;
  line-height: 27px;
  vertical-align: middle;
  padding-top: 7px;
  font-size: 14px;
  font-weight: 400;
  margin-right: 5px;
}

.company-suffix {
  position: absolute;
  z-index: 5000;
  left: 43px;
  color: rgb(44, 44, 81);
  padding: 0;
  line-height: 27px;
  vertical-align: middle;
  padding-top: 7px;
  font-size: 14px;
  font-weight: 400;
  margin-right: 5px;
}

.company-appended {
  padding-left: 15px;
  color: #2c2c51;
}

.errorMsg {
  color: #cf5a5a;
  font-weight: bold;
}

.event_date_st {
  position: static;
  height: 21px;
  left: 0px;
  top: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #343c42;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.subject_sub_date {
  position: static;
  width: 500px;
  left: 0px;
  top: 1.5px;
  padding-left: 21px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.order_new_report_btn {
  position: static;
  left: 16px;
  top: 50px;
  padding: 10px 16px 10px 16px;
  border-radius: 4px;
  border: 2px solid #21598a;
  background-color: transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #21598a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}

.order_confirm_message {
  /* position: absolute;
  width: 627px;
  height: 72px;
  left: 203px;
  top: 495px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  margin: 5px 0px 0px 30px;
  /* or 36px */

  /* text-align: center;  */

  /* Secondary Gray */

  color: #747474;
}

.credit_repots_panel_btn {
  position: static;
  background-color: transparent;
  padding: 10px 16px 10px 16px;
  border-radius: 4px;
  left: 16px;
  top: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  width: 300px;
  line-height: 150%;
  color: #21598a;
  border: 2px solid #21598a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}

.quick_order_confirm_message {
  /* position: absolute;
  width: 627px;
  height: 72px;
  left: 235px;
  top: 460px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  /* or 36px */

  /* text-align: center; */
  color: #747474;
  font-size: 24px;
}

.quick_order_new_report_btn {
  position: static;
  left: 16px;
  top: 10px;
  padding: 10px 16px 10px 16px;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  background-color: transparent;
  border: 2px solid #21598a;
  color: #21598a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}
.quick_credit_repots_panel_btn {
  position: static;
  left: 16px;
  top: 50px;
  padding: 10px 16px 10px 16px;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  border: 2px solid #21598a;
  background-color: transparent;
  color: #21598a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}
.maincredit {
  background-color: white;
}

.eventinfo {
  margin-left: 10px;
}
.buttonsedit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.markdup_edit_btn {
  padding-right: 12px;
  padding-bottom: 5px;
}

.markdupli {
  height: 43px;
  min-width: 155px;
  border-radius: 4px;
  text-align: center;
  background: #21598a;
  margin-right: 12px;
  align-items: center;
  padding: 4px 8px;
  color: #ffffff;
  border: none;
}
.edit_btn {
  position: static;
  min-width: 76px;
  height: 43px;
  left: 167px;
  top: 0px;

  /* Alliance Blue */

  background: #21598a;
  border-radius: 4px;
  color: #ffffff;

  border: none;
  /* Inside auto layout */
  margin: 0px 24px 0px 12px;
}
.eventbuttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  position: static;
  min-width: 70px;
  height: 22px;
  left: 0px;
  top: 0px;
  border: none;
  background: #21598a;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 400;
  font-size: 12px;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 12px;
}

.btnClass {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  position: static;
  height: 30px;
  left: 0px;
  top: 0px;
  background: #cf5a5a;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 24px;
}

.btnClassClient {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  position: static;
  height: 30px;
  left: 0px;
  top: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  border: none;
  color: #ffffff;
  /* Alliance Blue */

  background: #21598a;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 24px;
}
.bagdesuccess {
  height: 22px;
  font-weight: 400;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  padding: 4px 8px;
  margin-left: 5px;
  background: #40b753;
}

.submit_subject_grp_name {
  width: 192px;
  height: 21px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  padding-top: 2.5px;
  font-size: 18px;
  line-height: 21px;
}
.back_nav_icon {
  display: flex;
  flex-direction: row;
}

.react_back_arrow_icon {
  cursor: pointer;
  color: #21598a;
  font-size: 27px;
  padding: 0px 0px 2px 0px;
}

.disablepanel {
  background: #ffe6e6;
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

/*********************alerts.js styles*************/
.full_event_name {
  font-size: 20px;
  line-height: 20px;
  padding-top: 5px;
  margin-top: 10px;
  width: fit-content;
}

.padding_left_arrow {
  padding-left: 10px;
}
.alert_container_bg {
  width: 101%;
  height: calc(100vh - 198px);
  left: 0px;
  top: 241px;
  background: #f4f4f4;
}

.general_container_bg {
  width: 1540px;
  height: 783px;
  left: 0px;
  top: 241px;
  background: #ececec;
}

.credit_container_bg {
  width: 1540px;
  height: 783px;
  left: 0px;
  top: 241px;
  background: #ececec;
}

.sidebarNav_discussion {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0px;
  min-height: 70vh;
  top: 200px;
  border-right: 1px solid #d8d8d8;
  background: #f6f8fa;
}

.sidebar_menu_discussion {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  color: #fcfdff;
  border: none;
  position: static;
  background-color: transparent;
  width: 300px;
  height: 40px;
  left: 0px;
  top: 2px;
  margin: 10px;
  color: #000000;
  align-content: center;
  align-items: center;
  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}
.sidebar_menu_discussion_inactive {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: static;
  height: 40px;
  left: 0px;
  background-color: transparent;
  top: 2px;
  padding: 12px 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  border: none;
  align-self: stretch;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}
.create_new_catgorie {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13.5px;
  position: absolute;
  width: 287px;
  height: 45px;
  left: 6px;
  top: 1580px;
  border-color: #21598a;
  color: #21598a;
  /* Base Wireframe/Blue */
  background: #ffffff;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.create_new_catgorie:hover {
  background: #21598a;
  color: #ffffff;
}
.alert_discussion_tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  position: static;
  width: 300px;
  height: 40px;
  border: none;
  color: #ffffff;
  left: 0px;
  top: 80px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #21598a;

  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}

.categories_header {
  min-width: 88px;
  height: 21px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 17px;
  color: #343c42;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 20px 8px;
}
.create_new_post_discussion {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  height: 43px;
  left: 950px;
  top: 16px;
  border: none;
  color: #ffffff;
  /* Alliance Blue */

  background: #21598a;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 6px;
}
.discussion_bg {
  width: 101%;
  background: #f4f4f4;
  // height: calc(100vh - 200px);
  left: 0px;
  top: 241px;
}
.discussion_bg_credit {
  background-color: #ffffff;
  //height: calc(100vh - 200px);
}

.discussion_bg_credit_main {
  height: calc(100vh - 206px);
}
.discussion_bg_fix {
  width: 100%;
  height: calc(100vh - 200px);
  left: 0px;
  top: 241px;
}
.discussion_bg_secondpage {
  background: #ffffff;
}
.col_9_hight_different {
  min-height: 72vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 79%;
}

.discussion_list_style {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;

  position: absolute;
  width: 1160px;
  height: 70px;
  left: 330px;
  top: 317px;

  background: #ffffff;
}
.discussion_list_header {
  height: auto;
  padding-left: 13px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #343c42;
  padding-bottom: 5px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 0px;
}
.discussion_list_body {
  width: 100%;
  min-height: 20px;
  left: 12px;
  top: 34px;
  padding-left: 12px;
  padding-top: 1px;
  padding-bottom: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  /* Text Blue Gray */
  margin: 6px, 0px;
  color: #3b4a68;
}
.discussion_static_list_body {
  height: auto;
  padding-left: 5px;
  left: 12px;
  top: 34px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height */
  color: #323232;
}
.discussion_chat_body {
  height: 10px;
  left: 12px;
  top: 34px;
  padding: 8px 7px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height */
  color: #000000d0;
}

.create_alert_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  width: 166px;
  height: 43px;
  left: 923px;
  top: 16px;
  /* Alliance Blue */
  background: #21598a;
  border-radius: 4px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
}

.Alert_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  position: static;
  width: 100%;
  height: auto;
  left: 16px;
  top: 75px;
  background: #f4f6f9;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px;
}
.alert_details_box {
  min-width: 1150px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  position: static;
  height: 137px;
  left: 16px;
  top: 75px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px;
}
.Alert_title_name {
  position: static;
  height: 16px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #21598a;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 6px;
  padding-top: 10px;
}
.alert_date {
  display: flex;
  flex-direction: row;
  position: static;
  min-width: 100px;
  height: 16px;
  left: 945px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  padding-top: 7px;
  padding-left: 0px;
  /* Secondary Text Gray */

  color: #747474;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 6px;
}

.alert_date div {
  padding-left: 4px;
}

.Alert_subjectname_date_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.alert_description {
  width: 100%;
  height: auto;
  padding: 5px;
  padding-top: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  color: #000000;
}
.alerts_button_box {
  width: 100%;
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.show_details_link {
  background-color: transparent;
  border: none;
  text-decoration: none;
  position: static;
  height: 19px;
  left: 940px;
  top: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #21598a;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.arrow_icon {
  font-size: 35px;
}
.alert_btn_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.alert_btn_box2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.asked_for_cred_ref {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.alerticon {
  display: flex;
  padding-left: 3px;
  padding-top: 25px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  /* Text Dark Blue Gray */

  color: #343c42;
}
.allalerts {
  padding-left: 7px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  /* Text Dark Blue Gray */

  color: #343c42;
}

.alert_label {
  position: static;
  height: 16px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000 !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}

.show_details_container {
  position: static;
  width: 100%;
  height: auto;
  left: 0px;
  top: 0px;
  background: #f4f6f9;
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 1px 15px 0px 0px;
}

.alert_details_template {
  padding: 10px;
  min-width: 1300px;
  width: auto;
  height: auto;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.alert_details_template p {
  padding: 4px;
}

/*********************alerts.js styles*************/
.rs-radio-wrapper .rs-radio-inner:before,
.rs-radio-wrapper:before {
  width: 14px !important;
  height: 14px !important;
  border: 1px solid gray;
  border-radius: 2px;
}
.rs-radio-wrapper {
  top: 14px !important;
}
.rs-radio-inner:after {
  margin-top: 4px !important;
  margin-left: 4px !important;
}
.rs-radio-checked {
  color: #517bcc;
}
.supplier-prefix {
  position: absolute;
  z-index: 1;
  color: rgb(44, 44, 81);
  padding: 0;
  line-height: 27px;
  vertical-align: middle;
  padding-top: 6px;
  font-size: 14px;
  font-weight: 400;
  margin-right: 5px;
  padding-left: 3px;
}

.title {
  font-weight: 600;
}
.create_event_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.create_event_btn_box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 5px 12px 12px;
  position: static;
  min-width: 150px;
  height: 43px;
  left: 1269px;
  top: 0px;

  // background: #21598A;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 10px 0px;
}
.nested_form {
  width: 50%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  padding: 5px 10px;

  border-radius: 4px;
  border: 1px solid #d8d8d8;
  margin-right: 14px;
  margin-left: 20px;
  margin-top: 27px;
}

.business_nested_form {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
}

/*********showdetails.js styles*********/
.eventinfo {
  width: 100%;
  margin: 20px 0px 0px 10px;
}
.event_info_align {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.submit_subject_count p {
  padding-top: 18px;
}

.fillcredit_count {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: static;
  height: 21px;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 22px;
}
.fillcredit_count p {
  padding-left: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.slash_icon {
  color: #000000;
  font-size: 25px;
  margin: 0px;
  padding-bottom: 3px;
}
.eventinfo h5 {
  color: #000;
}

.grouptext {
  margin-top: 13px;
  font-family: "Roboto";
}

.card p {
  display: flex;
  justify-content: space-between;
  height: 21px;
  padding: 3px 2px 3px 2px !important;
  left: 0px;
  top: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
.card {
  background: #ededed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 385px;
  border: none;
}
.wrap_up_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  height: 43px;
  left: 16px;
  top: 16px;
  color: #ffffff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* Alliance Blue */
  background: #21598a;
  border-radius: 4px;
  /* Inside auto layout */
  // flex: none;
  // order: 0;
  // flex-grow: 0;
  // margin: 12px 0px;
}

/*********showdetails.js styles*********/

/****Memebers styles*******/

.informationhead {
  position: static;
  width: 192px;
  height: 21px;
  left: 16.94px;
  top: 0px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  color: #323232;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 12px;
}

.creditfor {
  position: static;
  height: 19px;
  left: 10px;
  top: 20px;
  padding: 0px 10px 0px 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.searchbardatbase {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  position: static;
  width: 424px;
  height: 39px;
  left: 158px;
  top: 10px;

  background: #f8f8f8;
  border: 1px solid #b3b3b3;
  box-sizing: border-box;
  border-radius: 2px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}
.searchbarhead {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-bottom: 3px;
  border-bottom: 1px solid #d8d8d8c7;
  margin-top: 4px;
}
.searchbar {
  margin-left: 10px;
  width: 424px;
  height: 39px;
  background: #f8f8f8;
  /* Inside auto layout */
}

.navbardatabase li {
  display: flex;
  padding: 15px;
}
.active {
  color: #21598a;
}
.resdetails {
  min-width: 131px;
  height: 21px;
  left: 17px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;

  margin: 10px 10px 10px 0px;
}

.headtable {
  position: absolute;
  width: 99.5%;
  height: 44px;
  top: 344px;
  padding: 5px 10px;
  background: #f8f8f8 !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #21598a;
}
.headtable th {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  background: #d4dee8;
}

.tablevalues {
  padding: 12px;
  position: absolute;
  top: 385px;
  width: 100%;
  height: 51px;
  margin-top: 10px;
}
.linespace {
  margin-right: 40px;
  font-weight: 200;
}
.linespaceFrom {
  margin-right: 60px;
  font-weight: 200;
}
.tagcompany {
  color: #21598a;

  margin: 0;
}

.bgcolor_headdatabase {
  padding: 12px;
  min-width: 1417px;
  width: auto;
  height: 51px;
  margin-top: 10px;
  background: #f4f6f9;
}
.infodatabase {
  margin-top: 7px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-left: 17px;
  margin-right: 10px;
  line-height: 19px;
  color: #000000;
}
.fromdatabase {
  margin-top: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-left: 10px;
  margin-right: 10px;
}
.fliterdatabase {
  margin-top: 1px;
  margin-left: 22px;
  margin-right: 10px;
}

.cont_database {
  padding: 10px;
  margin-left: 30px;
}
.members_table_header {
  background: #d4dee8;
  // color: #000000;
}

.headtable_members {
  position: absolute;
  top: 212px;
  min-width: 100%;
  width: auto;
  padding: 10px;
  height: 44px;
  margin-top: 20px;

  font-size: 16px;
  line-height: 19px;
}

.headtable_members th {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #21598a;
}

.tablevalues_members {
  padding: 4px 0px 0px 0px;
  min-width: 100%;
}
.linespacemem {
  padding-left: 5px;
  min-width: 180px;
  font-weight: 200;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

table {
  border: none;
}

.showusershead {
  color: #21598a;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  // margin: 10px;
}
.showusersheadopt {
  position: static;
  min-width: 32px;
  width: auto;
  height: 19px;
  left: 0px;
  top: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #323232;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 10px;
}

// .bgcolor_headdatabase{
//   padding: 12px;
//   position: absolute;
//   width: 1417px;
//   height: 51px;
//   top: 200px;
//   margin-top: 10px;
//   background: #F4F6F9;
// }
.members_panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  width: 100%;
  height: 59px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

/****Memebers styles*******/

/*groupdatabase styles*/
.datepickerdatabase {
  min-width: 271px;
  padding-right: 24px;
  border: none;
  border-radius: 4px;
  margin: 10px -14px 10px 10px;
}
.rows {
  border: none;
  cursor: pointer;
  padding: 5px;
  width: 200px;
}
.contentdatabase {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;
  height: calc(100vh - 258px);
  font-family: Roboto;
  font-style: normal;
  font-weight: 400px;
  font-size: 16px;
  line-height: 19px;
  background: #f6f8fa;
  border-radius: 4px;
  margin-top: 7px;
}
.group_database_panel {
  margin: 10px 0px;
}

.subject_data_box {
  width: 100% !important;
  margin: 0px;
}

.title_box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
  height: 100%;
}

.title_box div:nth-child(1) {
  min-width: 120px;
  max-width: 120px;
}

.title_box div:nth-child(2) {
  min-width: 205px;
  max-width: 205px;
}
.title_box div:nth-child(3) {
  min-width: 90px;
  max-width: 100px;
}

.custom_btstrp_select {
  width: 110px;
  color: #555b6e;
  font-size: 14px;
  padding-right: 20px;
}
.custom_btstrp_select_infotype {
  width: 85px;
  color: #555b6e;
  font-size: 14px;
  padding-right: 20px;
}

/*groupdatabase styles*/

/** discussion new styles**/

.user_add_edit {
  label {
    padding-top: 5px;
  }
}
.btn_inactive {
  background: gray;
  padding: 2px 10px;
  border-radius: 3px;
  border: 0px;
  color: whitesmoke;
}
.database_filter {
  // background-color: red;
  display: grid;
  height: 65vh;
  place-items: center;
  span {
    font-size: 16px;
    color: gray;
  }
}
.text_align {
  th {
    text-align: center;
  }
  td {
    text-align: center;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}
.center_items {
  width: 100%;
  padding-top: 10%;
  display: grid;
  place-items: center;
}
.rs-picker-tag .rs-picker-search-input > input {
  background: transparent !important;
}
hr {
  padding: 0px;
  margin: 0px;
  height: 1px;
  color: #afc9f0;
}
.discussion_1st_page_post {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  position: relative;
  width: 100%;
  min-height: 95px;
  height: auto;
  list-style-type: none;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e4e4e4;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.textarea_chat_hight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 7px;
  position: static;
  min-width: 1073px;
  height: auto;
  left: 16px;
  top: 120px;
  background: #ffffff;
  border: 1px solid #d7e1ea;
  box-sizing: border-box;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}
.post_credit_ref_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 8px;

  position: static;
  width: 230px;
  height: 40px;
  left: 0px;
  top: 0px;

  /* Alliance Blue */
  border: none;
  background: #21598a;
  border-radius: 4px;
  color: #ffffff;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.send_btn_chat {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 22px;
  position: static;
  height: 40px;
  left: 188px;
  top: 0px;
  background: #21598a;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 6px 0px 0px 0px;
}
.textaera_div_bg {
  display: flex;
  flex-direction: column;
  padding: 12px 14px;
  height: 125px;
  background: #fafafa;
  border-radius: 4px;
  border: 1.5px solid #e0e0e0;
}
.chat_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;

  position: absolute;
  width: 960px;
  height: 80px;
  left: 0px;
  top: 0px;

  background: #f3f8ff;
  border-radius: 4px;
}
.btn_refresh {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.create_post_chat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 10px;
  position: static;
  width: 100%;
  min-height: 81px;
  left: 16px;
  top: 75px;
  list-style-type: none !important;
  background-color: #f3f8ff;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
}
.credit_report_card_chat_bg_3 {
  display: flex;
  flex-direction: column;
  align-items: flex-center;
  padding: 12px;

  position: static;
  width: 1170px;
  height: 347px;
  left: 0px;
  top: 700px !important;

  background: #f0f0f0;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 16px 0px;
}

.blue_bg_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;

  position: absolute;
  width: 1075px;
  height: 351px;
  left: 0px;
  top: 454px;

  background: #f3f8ff;
  border-radius: 4px;
}

// new css for bg of last form of credit

.credit_form_chat_new {
  display: flex;
  flex-direction: column;
  align-items: flex-center;
  padding: 12px;

  position: absolute;
  width: 1075px;
  height: 590px;
  padding: 10px 10px;
  top: 300px;

  background: #f0f0f0;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 10px 30px;
}

.sidebarNav_discussion_credit_post {
  width: 20%;
  min-height: 72vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0px;
  top: 200px;
  border-right: 1px solid #d8d8d8;
  background: #f6f8fa;
}
.create_new_catgorie_credit_post {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 96%;
  height: 48px;
  /* Base Wireframe/Blue */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  /* Base Wireframe/Blue */
  background: #21598a;
  border-radius: 4px;
  color: #ffffff;
  border: none;
  // margin-bottom: 10px;
  border: none;
  margin: 10px, 10px;
  margin-left: 6px;
  position: static;
  margin-bottom: 10px;
}

.Alert_bg {
  background: #f4f4f4;
  width: 79%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebarNav_discussion_credit_chat {
  width: 312px;
  min-height: 1410px;
  left: 0px;
  top: 200px;
  align-items: flex-start;
  background: #f4f6f9;
}

#btnSearch,
#btnClear {
  display: inline-block;
  vertical-align: top;
}
.create_topic_btn_editor {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
// color is blue for chat box
.create_post_chat_new {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 6px;
  position: static;
  height: auto;
  left: 16px;

  list-style-type: none !important;
  background-color: #f3f8ff;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px;
}

.create_post_chat_new_tem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  position: static;
  min-height: 80px;
  left: 16px;
  top: 75px;
  list-style-type: none !important;
  background-color: #f3f8ff;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px;
  width: 99%;
}

.create_post_chat_new_green {
  padding: 12px;
  width: 99%;
  min-height: 80px;
  list-style-type: none;
  background-color: #f0f9f4;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 10px !important;
}

.output_credit_form_card {
  display: flex;
  flex-direction: column;
  padding: 12px 12px 12px 32px;
  align-items: center;
  justify-content: center;
  position: static;
  width: 100%;
  min-height: 300px;
  left: 0px;
  top: 454px;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  color: #323232;
  background: #f3f8ff;
  border: none;
  margin: 3px 1px;
}

.output_static__credit_form_card {
  display: flex;
  flex-direction: column;

  position: static;
  width: 100%;
  min-height: 550px;
  left: 0px;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  color: #323232;
  background: #f3f8ff;
  border: none;
  margin: 3px 1px;
}
.line_hight {
  line-height: 1px;
  text-decoration: none;
  cursor: pointer;

  display: block;

  margin-top: 0px 0px;
  width: 90px;
  height: 1px;
}
.textaera_div_bg_for_credit_post {
  display: flex;
  flex-direction: column;
  align-items: flex-center;
  padding: 12px 16px;
  position: absolute;
  width: auto;
  height: auto;

  background: #fafafa;
}
.create_new_post_discussion_new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  height: 43px;
  left: 950px;
  top: 16px;
  border: none;
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* Alliance Blue */

  background: #21598a;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}
.sidebar_menu_discussion_new {
  display: flex;

  flex-direction: row;
  justify-content: space-between;

  align-items: center;

  padding: 12px 16px;

  position: static;

  width: 100%;

  height: 40px;

  border: none;

  color: #ffffff;

  left: 0px;

  top: 80px;

  font-family: Roboto;

  font-style: normal;

  font-weight: 500;

  font-size: 14px;

  line-height: 16px;

  background: #21598a;

  /* Inside auto layout */
}

.credit_report_card_bg_2_new {
  display: flex;
  flex-direction: column;
  align-items: flex-center;
  padding: 12px;
  position: static;
  min-height: 430px;
  width: 100%;
  left: 0px;
  top: 239px;
  margin-left: 0;
  background: #ffffff;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 16px 0px;
  border: 1.25px solid #dadada;
}
.credit_report_card_bg_3_new {
  display: flex;
  flex-direction: column;
  align-items: flex-center;
  padding: 12px;
  position: static;
  min-height: 480px;
  width: 100%;
  height: auto;
  left: 0px;
  top: 680px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 16px 0px;
}
.credit_report_card_bg_1_new {
  display: flex;
  flex-direction: column;
  align-items: flex-center;
  padding: 12px;
  position: static;
  min-height: auto;
  left: 0px;
  top: 0px;
  background: #ffffff;
  width: 100%;

  border-radius: 4px;

  /* Inside auto layout */
  border: 1.25px solid #dadada;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
}
.general_chat_msg {
  position: static;
  height: 32px;
  left: 12px;
  top: 36px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}
.Ask_for_credit_ref_btn {
  padding: 12px 24px;
  top: 16px;
  border: none;
  color: #ffffff;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 10px 0px;
  font-weight: 500px;
}

.chat_date_details {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.create_credit_post_date {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.date_credit {
  position: static;
  height: 12px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}
.creditPost_collapse_panel {
  display: flex;
  width: 100%;
  height: 65px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
}
.credpost_panel_collapsable_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 125px;
  background: #f3f8ff;
  border-radius: 4px;
}

.Crdit_last_form_Modal {
  font-size: small;
  padding: 10px;
  position: fixed;
}
.Crdit_last_form_Modal_form {
  padding: 30px;
}
.creditPost_panel {
  border-top: 1px solid #d8d8d8;
  width: 1075px;
}
.expand_creditChat {
  font-weight: 500;
  padding-left: 7px;
  font-size: 16px;
  line-height: 18.75px;
  color: #21598a;
  position: static;
  margin-top: 15px;
  padding-left: 14px;
  padding-bottom: 10px;
}

.expand_Alertpanel {
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  float: right;
  line-height: 18.75px;
  color: #21598a;
  position: static;
  margin-top: 35px;
}
.event_template_align {
  display: flex;
  // width: 100%;
  // flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px, 20px;
  margin-top: 10px;
}
.padding_para {
  padding: 10px 5px;
}
.expand_alert_panel_btn {
  width: 100%;
}
.Alert_modal_container {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 5px;
  column-gap: 10px;
  width: 100%;
  padding: 12px;
  padding-top: 5px;
  padding-bottom: 0;
}
.Alert_modal_container_subj {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  column-gap: 10px;
  padding: 12px;
  padding-bottom: 0;
  padding-top: 3px;
}
.lengthComments {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.alertrender_forms_subj {
  width: 50%;
  display: flex;
  padding-left: 13px;
}
.alertrender_forms {
  width: 50%;
  padding-left: 10px;
}
.alertrender_forms_date {
  width: 32.5%;
  padding-left: 10px;
}
.commentsdiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 98%;
  background: #fafafa;
  border: 1px solid #c1c1c1;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.commentsdiv_label {
  padding-left: 10px;
}
.lengthComments_alert {
  width: 100%;
  background-color: #fafafa;
  padding-left: 20px;
}
.location_details_template {
  position: static;
  width: 203px;
  height: 16px;
  left: 0px;
  top: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #323232;

  /* Inside auto layout */

  flex: none;
}

.location_details_template_para {
  position: static;
  height: 21px;
  left: 0px;
  top: 0px;
  padding: 8px 8px;
  width: 800px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #0a0a0abd;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.disable_steps {
  pointer-events: none;
  opacity: 0.4;
}
.sidebar_user {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  cursor: not-allowed;
}
.account_user_sub {
  border: 1px solid #747474;
  border-radius: 4px;
  padding: 10px;
}
.active_style {
  color: white;
  background-color: green;
  padding: 0px 6px;
  border-radius: 5px;
  font-size: 14px;
}

.is_active_tag {
  color: white;
  background-color: green;
  padding: 0px 6px;
  border-radius: 5px;
}
.is_inactive_tag {
  color: white;
  background-color: red;
  padding: 0px 6px;
  border-radius: 5px;
}

.manager_name {
  position: static;
  height: 21px;
  // left: 0px;
  top: 0px;
  padding: 8px 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #0a0a0aa9;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px;
}
.mk_dp_modal_body {
  min-height: 200px;
  padding: 15px;
  border-bottom: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
}
.md_subject_names {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 22px;
  border: none;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 3px;
  background: #21598a;
  border-radius: 4px;
}

.md_primary_subject {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 22px;
  border: none;
  font-size: 14px;
  color: #000000;
  margin-bottom: 3px;
  background: #f1f1f1;
  border-radius: 4px;
}

.mark_dupli_row {
  background: #d8d8d8;
  opacity: 0.7;
}

.manager_name_event_details p {
  position: static;
  height: 21px;
  left: 0px;
  top: 0px;
  padding: 0px 0px 0px 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #0a0a0aa9;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}
.edit_btn_box {
  padding-left: 36px;
}
.chat_message {
  padding: 7px 0px 0px 7px;
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.chat_message_date {
  display: flex;
  justify-content: flex-end;
  min-width: 69px;
  height: 10px;
  left: 12px;
  top: 34px;
  padding: 8px 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #0a0a0a;
}
.group_database_container {
  background: #f4f6f9;
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.subject_template_1 {
  width: 100%;
  min-height: auto;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 30px;
  column-gap: 10px;
}

.subject_template_2 {
  width: 100%;
  min-height: auto;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 30px;
  column-gap: 10px;
  margin-top: 30px;
}
//download wrap-up styles
.subj_list_Download {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  min-width: 95%;
  height: 50px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  border: 1px solid #dfebf1;
}
.title_color_list {
  min-height: 350px;
  left: 10px;
  top: 0px;
  color: #000000 !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 0px 15px 35px 0px;
  margin: 0px;
}
.no_reports_avail_msg {
  text-align: center;
  padding: 24px;
  // background: #F6F8FA;
  // border-radius: 4px;
}

.mark_dupli_bg {
  background: #d8d8d87c;
  color: gray;
}

.credit_form_container {
  width: 100%;
}

.credit_form_container_list {
  width: 100%;
}

.credit_fill_container {
  min-width: 100%;
}
.cursor_chat {
  cursor: pointer;
}

.credit_chat_desc {
  position: static;
  padding-left: 15px;
  padding-top: 10px;
  width: auto;
  height: 32px;
  left: 12px;
  top: 36px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px;
}
.bs_dot {
  font-size: 16px;
  font-weight: 900;
}
.go_dot {
  font-size: 10px;
  font-weight: 900;
}
.col_9_hight {
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;

  height: 55%;
  width: 79%;
}
.col_9_hight_credit {
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;

  height: 90%;
  width: 79%;
}

.event_completed {
  // margin-right: 3px;
  // color: white;

  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  margin-left: 5px;
  position: static;
  height: 22px;
  left: 221px;
  top: 0px;

  /* Attention Orange */

  background: #000000;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}
.fill_credit_data {
  padding-left: 0px;
}
.subject_list_empty_msg {
  width: 100%;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  margin-top: 100px;
  min-height: 150px;
}

.linespacemem_2 {
  font-weight: 200;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  min-width: 129px;
  width: auto;
  color: #000000;
}
.linespacemem_3 {
  font-weight: 200;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  min-width: 250px;
  width: auto;

  color: #000000;
}

.linespacemem_1 {
  font-weight: 200;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  width: 130px;
  color: #000000;
}

.linespacemem_data1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #000000;
}

.linespacemem_data2 {
  font-weight: 200;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  min-width: 300px;
  width: auto;
  color: #000000;
}

.linespacemem_data3 {
  font-weight: 200;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  min-width: 400px;
  width: auto;
  color: #000000;
}

.disable_steps1 {
  display: none;
}
.align_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.missing_tabs {
  display: none;
}
.subject_form_container {
  margin: 30px;
  padding: 45px;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 10px;
}
.subject_details_title {
  margin: 10px 60px 0px 60px;
  padding-bottom: 10px;
  border-bottom: 1px solid #555b6e27;
}

.md_subject_names:active {
  background-color: #21598a;
  transition: all 0.6s;
  transform: scale(0.9);
}

.sub_edit_btn_box {
  width: 100%;
  display: flex;
  padding-right: 48px;
  justify-content: flex-end;
  align-items: center;
}
.discussion_list_date {
  padding-left: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #747474;
  padding-bottom: 5px;
}
.event_status_upcoming {
  padding: 4px 4px;
  position: static;

  height: 22px;
  left: 221px;
  top: 0px;

  /* Attention Orange */
  background-color: #ffff00b6;
  color: #000000;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px 10px 10px;
}

.credit_download_btn {
  background: transparent !important;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}
.add_subject_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  border: none;
  margin: 30px 10px;
  color: #ffffff;
  height: 43px;
  background: #21598a;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
.event-subject_form_container {
  padding: 10px;
}
.subjects_filled_count {
  padding-left: 25px;
}
.fillcreditheadsub_showdetails {
  margin-bottom: 8px;
}

.csontainer {
  background: #ffffff;
  // padding: 10px, 10px;
}

.btn_true {
  background: #40b753;
}
.btn_false {
  background: #21598a;
}
.show_details_template {
  padding: 10px;
}
.subject_title_align {
  display: flex;
  flex-direction: row;
  color: #000000;
  font-weight: 500;
}
.sub_name_sd {
  font-size: 16px;
  margin-bottom: 4px;
  padding: 0px 4px 4px 0px;
}
.no_cred_data_msg {
  color: #00000085;
}
.refresh_credit {
  animation: mymove 2s infinite;
}

@keyframes mymove {
  50% {
    transform: rotate(360deg);
  }
}

.refresh_btn {
  float: right;
  padding-right: 50px;
  padding-top: 15px;
}
.refresh_btn1 {
  background-color: #e6eeff;
  padding: 4px 6px;
  border-radius: 4px;
}

.transaction_general {
  border: 2px solid #e6e6e6;
  padding: 15px;
  border-radius: 4px;
}
.transaction_general_inner1 {
  padding-bottom: 5px;
  font-size: 14px;
  color: gray;
  font-weight: bold;
}
.transaction_general_inner2 {
  padding-bottom: 5px;
  font-size: 14px;
  color: gray;
  font-weight: bold;
  text-align: end;
}
.transaction_general_ttl {
  text-align: end;
  color: #406dcd;
  padding-top: 10px;
  font-size: 16px;
  font-weight: bold;
}
.trasact_credit_search {
  background: #fbfbfb;
  border: 2px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 2%;
  width: 400px;
  outline-color: #0f385b;
}
.heading_transac {
  padding: 10px 0px 0px 20px;
  color: #406dcd;
}
.breadcrumb_transc {
  padding: 10px 0px 15px 20px;

  a {
    color: gray;
    font-size: 12px;
    font-weight: 600;
  }
  span {
    color: gray;
    font-size: 12px;
    font-weight: 600;
  }
}
.rs-nav-tabs .rs-nav-item.rs-nav-item-active {
  border: none !important;
  border-bottom: 4px solid #21598a !important;
  margin-bottom: -1px !important;
}
.rs-nav-item.rs-nav-item-active {
  color: #21598a !important;
  font-weight: bold !important;
}

.txt_align_center {
  text-align: center;
}
.txt_align_end {
  text-align: end;
  margin-right: 50px;
}
.subcompanies_img {
  width: 100px;
  margin-left: -2px;
  margin-top: -2px;
}
.btn_disabled {
  background: #fff;
  border: 2px solid #406dcd;
  color: #406dcd;
  pointer-events: none;
}
.rs-nav-bar {
  border-bottom: 1.5px solid #afc9f0;
}
.rs-nav-tabs.rs-nav-horizontal .rs-nav-bar {
  border-top: none !important;
}

.upload_report {
  height: 150px;
  padding-top: 20px;
  span {
    button {
      margin-top: 20px;
      margin-right: 40px;
    }
  }
}
.legal_dd1 {
  margin: 2%;
  margin-bottom: 0px;
}
.report_upload_error {
  color: gray;
  margin-top: 20px;
}
.scoring_popup {
  margin-left: 5%;
}
.scoring_col {
  font-size: 16px;
  color: gray;
  span {
    color: red;
  }
}
.scoring_col1 {
  color: #21598a;
  font-size: 16px;
  text-align: center;
}
.disable_comment_delete {
  display: none;
  pointer-events: none;
}

.database_pagination1 {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 65vh;
  padding: 100px;
  font-size: 18px;
  color: gray;
}
.event-reports-btn_box {
  padding: 0px 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: center;
  background-color: #ffffff;
}
.group_active {
  margin-left: 6px;
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  color: white;
  background-color: #40b753;
}

.group_inactive {
  color: #ffffff;
  margin-left: 7px;
  font-size: 12px;
  font-weight: 400;
  background-color: #cf5a5a;
}

.edit_btn_react_icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border: none;
  position: static;
  width: 100%;
  height: 100%;
  left: 1340px;
  top: 20px;
  color: #000;
  background: #e4e4e4;
  border-radius: 4px;
  float: right;
  /* Inside auto layout */
  top: 10px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}
.btns_align {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.active_checkbox {
  margin: 7px 4px 0px 4px;
  padding-top: 5px;
}
.form_align {
  margin: 5px 0px;
  padding-right: 5px;
}

.delete_react_icon {
  color: #d1cbcb;
}
.delete_react_icon:hover {
  color: #a09e9e;
}
.bdc_margin ul {
  margin-left: 12px;
}

.text_none {
  text-decoration: none;
}
.fileName {
  font-size: 14px;
  font-weight: 600;
}
.hide_field_data {
  background-color: red !important;
}
.nodata_label {
  background-color: red;
  display: block !important;
}
.scoring_col2 {
  margin-left: 20px;
  color: #21598a;
  font-size: 16px;
}
.database_pagination {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 8px;
}
.remove_padination {
  display: none;
  pointer-events: none;
}
.ask_credit_ref_title {
  position: static;
  min-width: 320px;
  height: 19px;
  left: 16px;
  top: 16px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  /* Text Dark Blue Gray */

  color: #21598a;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}
.ask_credit_ref_title h6 {
  padding: 0px 6px;
  font-family: "Roboto";
  color: #21598a;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
}
.exp_clps_hlp_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: center;
}
.subject_or_help {
  position: relative;
  top: 0px;
  margin-right: 6px;
}
.reports_avail_date_tag {
  padding-left: 20px;
}
.edit_credit_ref_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.creditRef_edit_container {
  padding: 15px;
}
.event_reports_title {
  color: #21598a;
  font-size: 24px;
  padding: 0px 0px 5px 24px;
}

.event_reports_th_status {
  width: 30px;
}
.event_reports_td td:nth-child(1) {
  width: 100px;
}
.event_reports_td td:nth-child(2) {
  width: 185px;
}
.event_reports_td td:nth-child(3) {
  width: 80px;
}
.event_reports_td td:nth-child(4) {
  width: 110px;
}
.event_reports_td td:nth-child(5) {
  width: 170px;
}
.event_reports_td td:nth-child(6) {
  width: 150px;
}

.member_mailto_btn {
  color: #0d6dfdc5;
  background-color: transparent;
}

.drap_files {
  border: 2px dotted gray;
  padding: 10px 20px;
  width: fit-content;
  color: gray;
  border-radius: 8px;
  font-weight: bold;
}
.drop_zone_files {
  display: flex;
  flex-direction: column;
}
.inner_file_name {
  color: gray;
  font-size: 14px;
}

.eye_color {
  position: absolute;
  margin-top: -30px;
  margin-left: 85%;
  color: gray;
}
.eye_color_reset {
  position: absolute;
  margin-top: -30px;
  margin-left: 350px;
  color: gray;
}

.warning_field {
  border: 1px solid red;
}

.redo_btn {
  font-size: 12px;
  padding: 5px 6px;
  margin-left: 15px;
}
.event-reports-wrap_box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.redo_merge_enable_repo_btn_box {
  padding-top: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

///////// new design fixes submitsubjects /////////

.header_submitsubjects {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  width: 99%;
  height: 43px;
  left: 16px;
  top: 12px;
  background: #f6f8fa;
  border: 2px dashed #21598a;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #343c42;
  margin: 16px 0px;
}

.buton_submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  height: 43px;
  left: 12px;
  top: 868px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 10px 10px;
}
.buton_fillcredit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  min-width: 230px;
  height: 42.7px;
  left: 0px;
  top: 443.84px;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 17px 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border: none;
}

.form_input_sub {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px 8px 18px;
  position: static;
  width: 100%;
  height: 40px;
  left: 0px;
  top: 25px;
  background: #fafafa !important;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px;
}
.subjects_submitted {
  position: static;
  width: 158px;
  height: 21px;
  left: 0px;
  top: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #3b4a68;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 5px;
}
.subdetails {
  position: static;
  min-width: 106px;
  width: auto;
  height: 19px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #21598a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 13px 0px;
}
.subject_info {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #21598a;
}

.head_credit {
  position: static;
  width: 115px;
  height: 19px;
  left: 0px;
  top: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3b4a68;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
}
.mainpage {
  background: #f6f6f6;
}
.eventname {
  height: 21px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #343c42;
  margin: 0px;
}

.subjects_submission {
  position: static;
  width: 116px;
  height: 19px;
  left: 16.94px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #21598a;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 12px;
}

.credit_details {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3b4a68;
}

.filllastcredit_details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  position: static;
  width: 99%;
  height: 43px;
  left: 16px;
  top: 12px;
  background: #f6f8fa;
  border: 2px dashed #21598a;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
}

.datestyle {
  color: #21598a;
}

// .all_groups{
// width: 84px;
// height: 21px;
// left: 28px;
// width: auto;
// font-family: 'Roboto';
// font-style: normal;
// font-weight: 400;
// font-size: 18px;
// line-height: 21px;
// color: #343C42;
// flex: none;
// order: 1;
// flex-grow: 0;
// }
.add_group {
  min-width: 150px;
  height: 21px;
  left: 28px;
  top: 0px;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin: 20px;
  color: #343c42;
}
.main_container {
  display: flex;
  justify-content: space-between;
  background: #f6f6f6;
}
.main_container_groups {
  background: #f6f6f6;
  display: flex;
}
.main_container_groups1 {
  background: #f6f6f6;
}

.box1 {
  order: 1;
}
.box1 {
  order: 2;
}
.groupid_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
}

.card_body_groupid {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 1408px;
  height: 231px;
  left: 16px;
  top: 67px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 12px 0px;
}

.nameofevent {
  position: static;
  min-width: 450px;
  height: 21px;
  left: 10px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #343c42;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.group_card_index:hover {
  background-color: #e0e0e0;
}
.flex_card_create_event {
  display: flex;
  justify-content: space-between;
}
.create_event_main_card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 1408px;
  height: 231px;
  left: 16px;
  top: 67px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 12px 0px;
}
.eventid_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.blue_bg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 1384px;
  height: 129px;
  left: 12px;
  top: 42px;
  background: #f2f5f8;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}
.event_bg {
  position: static;
  width: 98.5%;
  min-height: 129px;
  top: 42px;
  background: #f2f5f8;
  border-radius: 4px;
  flex: none;
  order: 1;
  margin: 10px;
  flex-grow: 0;
  padding: 10px;
}
.edit_event_new_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  height: 40px;
  background: #21598a;
  color: #ffffff;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 0px 5px 0px 12px;
}
.flex_to_left_groupid {
  flex: 0 1 auto;
  width: 150px;
  height: 100px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.Flex_for_discription {
  display: flex;
  justify-content: space-between;
}
.flex_for_location {
  display: flex;
  justify-content: space-between;
  display: flex;
  justify-content: flex-start;
  /* adjustment */
  position: relative;
}
.box_location2 {
  flex: 0 1 auto;
  min-width: 200px;
  padding: 5px;
  height: 100px;
  position: absolute;
  /* new */
  left: 50%;
  transform: translateX(-50%);
}
.style_for_location {
  position: static;
  width: 203px;
  height: 16px;
  left: 0px;
  top: 0px;
  padding: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #323232;
}
.styles_for_address {
  width: 202px;
  height: 16px;
  padding: 10px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
}
.orgnized_by_styles {
  position: static;
  width: 150px;
  height: 16px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}
.bg_for_div {
  padding: 12px;
  position: static;
  width: 99.5%;
  height: auto;
  left: 12px;
  top: 42px;
  background: #f2f5f8;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}
.show_btn_last_page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  width: 129px;
  height: 40px;
  left: 0px;
  top: 27px;
  color: #ffffff;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
}
.Flexfordivevent {
  display: flex;
  justify-content: space-between;
}
.label_style_for_modal {
  padding: 10px;
  width: 200px;
  float: left;
}
.input_style_for_modal {
  padding: 20px;
  margin-top: 10px;
  width: 30px;
  float: right;
}

.blue_btn_downloade_summery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  position: static;
  min-width: 186px;
  height: 29px;
  left: 0px;
  top: 0px;
  border: none;
  background: #21598a;
  border-radius: 4px;
  /* Inside auto layout */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.eventreports {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  position: static;
  height: 921px;
  left: 0px;
  right: 0px;
  top: 12px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 0px;
}

.eventhead {
  display: flex;
  justify-content: space-between;
}

.newDownloadbutton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  position: static;
  width: 113px;
  height: 28px;
  left: 1239px;
  top: 0px;
  background: #40b753;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.eventsreports__ {
  display: flex;
  justify-content: space-between;
}
.downloadwrapup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  width: 235px;
  height: 40px;
  left: 356px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  background: #40b753;
  border: 1px solid #40b753;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 12px;
}
.releasereports {
  margin-right: 10px;
  padding: 12px 24px;
  position: static;
  height: 40px;
  left: 0px;
  top: 0px;
  background: #21598a;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.showmore {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  width: 204px;
  height: 40px;
  left: 163px;
  top: 0px;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.eventicon {
  display: flex;
  margin-right: 10px;
}
.cardmainevents {
  display: flex;
  align-items: flex-start;
  position: static;
  height: 921px;
  left: 0px;
  right: 0px;
  top: 12px;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 0px;
}

.groupid_location_css {
  display: flex;
  justify-content: flex-start;
  position: relative;
}

/////////////Styles new fixes //////////////
.membersGroupspage1 {
  position: static;
  height: 19px;
  left: 21.33px;
  top: 0px;
  padding: 14px 0px 18px 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3b4a68;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}

.box1 {
  order: 1;
}
.box1 {
  order: 2;
}
.groupid_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
}
.group_box1 {
  order: 2;
  justify-content: center;
  height: 19px;
  font-weight: 400;
  margin-bottom: 10px;
}
.group_box2 {
  order: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 70%;
  height: 21px;

  padding: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #343c42;
}
.creat_event_flex {
  display: flex;
  justify-content: space-between;
}
.all_event {
  position: static;
  height: 21px;
  left: 26px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #343c42;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}
.Note_event {
  position: static;
  min-width: 401px;
  height: 16px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #555b6e;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 19px 0px 19px 0px;
}
.card_body_groupid {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 1408px;
  height: 231px;
  left: 16px;
  top: 67px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 12px 0px;
}

.group_card_index {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  width: 99%;
  height: 77px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 5px 7px;
}

.flex_card_create_event {
  display: flex;
  justify-content: space-between;
}
.create_event_main_card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 1408px;
  height: 231px;
  left: 16px;
  top: 67px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 12px 0px;
}
.eventid_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.blue_bg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 1384px;
  height: 129px;
  left: 12px;
  top: 42px;
  background: #f2f5f8;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}

.flex_to_left_groupid {
  flex: 0 1 auto;
  width: 150px;
  height: 100px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.Flex_for_discription {
  display: flex;
  justify-content: space-between;
}

.style_for_location {
  position: static;
  width: 203px;
  height: 16px;
  left: 0px;
  top: 0px;
  padding: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
}
.styles_for_address {
  width: 202px;
  height: 16px;
  padding: 10px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
}

.show_btn_last_page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  position: static;
  border: none;
  color: #ffffff;
  width: 170px;
  height: 43px;
  left: 0px;
  top: 0px;
  background: #21598a;
  border-radius: 4px;
  margin: 24px 0px;
}
.modal_btns_align {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.modal_btns_align button {
  margin: 5px;
}
.container_send_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.editor_style {
  overflow-y: auto;
  width: auto;
  height: 100%;
}
.eye_color_login {
  position: absolute;
  color: gray;
  padding-top: 10px;
  margin-left: 80%;
}

.filllastcredit_details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  position: static;
  width: 99%;
  height: 43px;
  left: 16px;
  top: 12px;
  background: #f6f8fa;
  border: 2px dashed #21598a;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
}
.collapsespace {
  background: #f6f6f6;
  height: auto;
}

.datestyle {
  color: #21598a;
}

.all_groups {
  width: 84px;
  height: 21px;
  left: 28px;
  width: auto;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #343c42;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  position: static;
  width: 113px;
  height: 28px;
  left: 1239px;
  top: 0px;
  background: #40b753;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}
.checkboxborder {
  overflow-y: auto;
  position: static;
  width: 357px;
  height: 130px;
  left: 0px;
  top: 25px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.checkboxborderedit {
  overflow-y: auto;
  position: static;
  width: 367px;
  height: 130px;
  left: 0px;
  top: 25px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
}
.label_style_for_modal {
  padding: 10px;
  width: 200px;
  float: left;
}

.Flexfordivevent {
  padding-top: 11px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  background: #f6f6f6;
}
.main_container_groups {
  background: #f6f6f6;
  display: flex;
}
.main_container_groups1 {
  background: #f6f6f6;
}
.container_groups_text {
  padding-bottom: 20px;
  position: fixed;
  top: 70%;
}
.alertpagenation_text {
  position: absolute;
  top: 70vh;
  width: 78%;
}
.box1 {
  order: 1;
}
.box1 {
  order: 2;
}
.groupid_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
}

.card_body_groupid {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 120px;
  height: 40px;
  left: 0px;
  top: 27px;
  border: none;
  /* Alliance Blue */
  color: #ffffff;
  background: #21598a;
  border-radius: 4px;
  margin: 6px 0px;
}
.blue_colored_checkbox {
  position: static;
  width: 18px;
  height: 18px;
  left: 100px;
  top: 10px;
  float: right;
  /* Button Bg Light Blue */
  padding: 10px;
  /* Alliance Blue */
  line-height: 15px;
  border: 2px solid #21598a;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 12px 0px;
}

.group_card_index:hover {
  background-color: #e0e0e0;
}
.flex_card_create_event {
  display: flex;
  justify-content: space-between;
}
.create_event_main_card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 1408px;
  height: 231px;
  left: 16px;
  top: 67px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 12px 0px;
}
.eventid_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.blue_bg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 1384px;
  height: 129px;
  left: 12px;
  top: 42px;
  background: #f2f5f8;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}

.flex_to_left_groupid {
  flex: 0 1 auto;
  width: 150px;
  height: 100px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.Flex_for_discription {
  display: flex;
  justify-content: space-between;
}

.style_for_location {
  position: static;
  width: 203px;
  height: 16px;
  left: 0px;
  top: 0px;
  padding: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3b4a68;

  margin-bottom: 5px;

  color: #323232;
}
.styles_for_address {
  width: 202px;
  height: 16px;
  padding: 10px;
}
.checkbox_header_style_edit {
  position: static;
  width: 400px;
  height: 16px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #3b4a68;

  margin-bottom: 15px;
}

.show_btn_last_page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  width: 129px;
  height: 40px;
  left: 0px;
  top: 27px;
  color: #ffffff;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
}
.Flexfordivevent {
  display: flex;
  justify-content: space-between;
}
.label_style_for_modal {
  padding: 10px;
  width: 200px;
  float: left;
}
.input_style_for_modal {
  padding: 20px;
  margin-top: 10px;
  width: 30px;
  float: right;
}

.eventreports {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  position: static;
  height: 921px;
  left: 0px;
  right: 0px;
  top: 12px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 0px;
}

.eventhead {
  display: flex;
  justify-content: space-between;
}

.newDownloadbutton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  position: static;
  width: 113px;
  height: 28px;
  left: 1239px;
  top: 0px;
  background: #40b753;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.eventsreports__ {
  display: flex;
  justify-content: space-between;
}
.downloadwrapup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  width: 235px;
  height: 40px;
  left: 356px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  background: #40b753;
  border: 1px solid #40b753;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 12px;
}

.showmore {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  width: 204px;
  height: 40px;
  left: 163px;
  top: 0px;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.eventicon {
  display: flex;
  margin-right: 10px;
}
.cardmainevents {
  display: flex;
  align-items: flex-start;
  position: static;
  height: 921px;
  left: 0px;
  right: 0px;
  top: 12px;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 0px;
}

.subjnamehead {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 12px;
  padding-top: 10px;
  position: static;
  width: 97.5%;
  height: 43px;
  left: 0px;
  top: 0px;
  background: #d4dee8;
  border-radius: 4px 4px 0px 0px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-left: 20px;
}
.groupid_location_css {
  display: flex;
  justify-content: flex-start;
  position: relative;
}

/////////////Styles new fixes //////////////
.box1 {
  order: 1;
}
.box1 {
  order: 2;
}
.groupid_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
}

.creat_event_flex {
  display: flex;
  justify-content: space-between;
}

.card_body_groupid {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 1408px;
  height: 231px;
  left: 16px;
  top: 67px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 12px 0px;
}

.group_card_index:hover {
  background-color: #e0e0e0;
}
.flex_card_create_event {
  display: flex;
  justify-content: space-between;
}
.create_event_main_card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 1408px;
  height: 231px;
  left: 16px;
  top: 67px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 12px 0px;
}
.eventid_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.blue_bg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 1384px;
  height: 129px;
  left: 12px;
  top: 42px;
  background: #f2f5f8;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}

.flex_to_left_groupid {
  flex: 0 1 auto;
  width: 150px;
  height: 100px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.Flex_for_discription {
  display: flex;
  justify-content: space-between;
}

.style_for_location {
  position: static;
  width: 203px;
  height: 16px;
  left: 0px;
  top: 0px;
  padding: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
}
.styles_for_address {
  width: 202px;
  height: 16px;
  padding: 10px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
}

.show_btn_last_page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  position: static;
  border: none;
  color: #ffffff;
  width: 170px;
  height: 43px;
  left: 0px;
  top: 0px;
  background: #21598a;
  border-radius: 4px;
  margin: 24px 0px;
}
.modal_btns_align {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.modal_btns_align button {
  margin: 5px;
}

.eye_color_login {
  position: absolute;
  color: gray;
  padding-top: 10px;
  margin-left: 80%;
}

.email_add {
  border: 1 px solid #dddddd;
  outline-color: #21598a;
  border-radius: 0.25rem;
  background-color: #f8f8f8;
  height: 40px;
}

.credit_application_link {
  font-size: 14px;
  color: #0f385b;
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
}

.Flexfordivevent {
  display: flex;
  justify-content: space-between;
}
.download-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  position: static;
  width: auto;
  height: 28px;
  left: 1239px;
  top: 0px;
  background: #40b753;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}
.checkboxborder {
  overflow-y: auto;
  position: static;
  width: 367px;
  height: 130px;
  left: 0px;
  top: 25px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
}
.label_style_for_modal {
  padding: 10px;
  width: 200px;
  float: left;
}
.input_style_for_modal {
  padding: 20px;
  margin-top: 10px;
  width: 30px;
  float: right;
}
.green_download_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  position: static;
  min-width: 158px;
  height: 32px;
  left: 186px;
  top: 0px;
  background: #40b753;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border: none;
}

.last_page_list_style {
  position: static;
  width: 1050px;
  height: 16px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  margin: 10px 0px;
}
.show_det_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: static;
  width: 120px;
  height: 40px;
  left: 0px;
  top: 27px;
  padding-left: 15px;
  border: none;
  /* Alliance Blue */
  color: #ffffff;
  background: #21598a;
  border-radius: 4px;
  margin: 6px 0px;
}
.blue_colored_checkbox {
  position: static;
  width: 18px;
  height: 18px;
  left: 100px;
  top: 10px;
  float: right;
  /* Button Bg Light Blue */
  padding: 10px;
  background-color: #e6eeff;
  /* Alliance Blue */
  line-height: 15px;
  border: 2px solid #21598a;
  box-sizing: border-box;
  border-radius: 4px;
}
.checkbox_label_styles {
  position: static;
  width: 300px;
  height: 21px;
  left: 10px;
  padding-bottom: 10px;
  float: left;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #323232;
}
.event_hending_top_center {
  position: absolute;
  width: 400px;
  height: 21px;
  left: 624px;
  top: 115px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #343c42;
}
.top_nav_with_arrow {
  position: static;
  width: 74px;
  height: 19px;
  left: 19.41px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #343c42;
}
.fill_cred_subject_styles {
  position: static;
  height: 19px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  padding-bottom: 10px;
  color: #323232;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4px 8px;
}

.checkbox_header_style {
  position: static;
  width: 400px;
  height: 16px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #3b4a68;
}
.modal_header_first_page {
  position: static;
  min-width: 106px;
  height: 21px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #343c42;
}
.my-modal {
  width: 488px; /* Occupy the 90% of the screen width */
  max-height: 587px;
}
.all_groups_style {
  position: static;
  height: 19px;
  left: -30px;
  top: 0px;
  color: black;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 10px;
  color: #343c42;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 24px;
}
.create_btn_modal {
  padding: 12px 24px;
  position: static;
  height: 43px;
  left: 0px;
  top: 0px;
  color: #ffffff;
  background: #21598a;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 18px 10px 20px;
}
.save_btn_modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
  position: static;
  width: 83px;
  height: 43px;
  left: 0px;
  top: 0px;

  /* Alliance Blue */
  color: #ffffff;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 24px;
  margin: 10px 0px;
}
.save_btn_modal {
  padding: 12px 24px;
  position: static;
  height: 43px;
  left: 0px;
  top: 0px;
  color: #ffffff;
  background: #21598a;
  border-radius: 4px;
  margin: 10px 0px;
}
.blue_color {
  position: static;
  width: 1033px;
  height: 40px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #21598a;
}
.margin_event_bg {
  margin-bottom: 10px;
}
.margin_bottom_event_bg {
  margin-bottom: 10px;
  margin-top: 10px;
}
.all_events_css {
  position: static;
  width: 70px;
  height: 19px;
  left: 19.41px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #323232;
  flex: none;
  order: 1;
  flex-grow: 0;
  // margin: 0px 12px;
}
.input_bg_color {
  background-color: #fafafa;
}

//////////styles /////////////

.group_download_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  min-width: 240px;
  height: 40px;
  left: 339px;
  top: 0px;
  background: #40b753;
  border: 1px solid #40b753;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.eventreportsheader {
  display: flex;
  padding-top: 20px;
}

.group_showmore_btn {
  padding: 12px 24px;
  position: static;
  height: 40px;
  left: 163px;
  top: 0px;
  background: #21598a;
  border-radius: 4px;
  border: none;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

//////////styles //////////

.font_for_labels_eventId {
  position: static;
  width: 202px;
  height: 16px;
  left: 12px;
  top: 93px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  flex: none;
  order: 3;
  flex-grow: 0;
  margin: 10px 0px;
}

.flex_blue_color_groupid {
  position: static;
  width: 250px;
  height: 19px;
  left: 1110px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #21598a;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}
.show_more_btn_for_groupId {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  width: 141px;
  height: 40px;
  left: 0px;
  top: 30px;
  background: #21598a;
  border-radius: 4px;
  color: #ffffff;
  /* Inside auto layout */
  border: none;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 8px;
}
.event_reports_not_available_eventId {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  position: static;
  height: 155px;
  left: 16px;
  right: 16px;
  top: 341px;
  background: #ffffff;
  border: 1.25px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 12px 0px;
}
.no_reports_yet {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px 0px;
  position: static;
  height: 83px;
  left: 60px;
  top: 60px;
  right: 60px;
  background: #f6f8fa;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 10px 20px;
}
.text_no_reports_avilable {
  position: static;
  width: 305px;
  height: 19px;
  left: 539.5px;
  top: 32px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #343c42;
  display: flex;
  text-align: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.class_for_inactive_title {
  position: static;
  width: 183px;
  height: 21px;
  left: 0px;
  top: 0.5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #c6c6c6;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.class_for_inactive_members {
  position: static;
  width: 91px;
  height: 19px;
  left: 21.33px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #c6c6c6;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}
///////////////styles ///////////

.groupsdisable {
  color: #c6c6c6;
  pointer-events: none;
}
.group_showsubj_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: static;
  width: 142px;
  height: 40px;
  left: 0px;
  top: 30px;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.group_submitsubj_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  height: 40px;
  left: 0px;
  top: 30px;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.save_btn_modal {
  margin-top: 10px;
}

.group_showmoredetails_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: #21598a;
  border-radius: 4px;
  color: #ffffff;
  flex: none;
  order: 2;
  flex-grow: 0;
  padding: 12px 24px;
  margin: 10px 0px;
  margin-left: 10px;
}

.textsubjectname {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  padding-left: 8px;
  color: #3b4a68;
}

.group_showrep_progress_btn {
  position: static;
  min-width: 210px;
  height: 40px;
  left: 0px;
  top: 0px;
  background: #21598a;
  border-radius: 4px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 12px;
}

.download-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  position: static;
  width: auto;
  height: 28px;
  left: 1239px;
  top: 0px;
  background: #40b753;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border: none;
}

.reports_Released_status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: static;
  min-width: 110px;
  height: 22px;
  left: 148px;
  top: 0px;
  background: #7b61ff;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.reports_expired {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  position: static;
  min-width: 102px;
  height: 22px;
  left: 148px;
  top: 0px;
  background: #4d4d4d;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.eventreportsheader {
  display: flex;
  padding-top: 15px;
}

.disabledrelease {
  pointer-events: none;
  background: #c6c6c6;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  width: 151px;
  height: 40px;
  color: #ffffff;
  border: none;
}

.iconforevents {
  display: flex;
}

.primary_btn {
  padding: 10px 24px;
  height: 47px;
  background: #21598a;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  border-radius: 4px;
  color: #ffffff;
  border: none;
}
.create_save_event_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  min-width: 83px;
  height: 43px;
  left: 0px;
  top: 0px;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border: none;
  color: #ffffff;
  margin: 14px 0px;
}
.disabledownload {
  pointer-events: none;
  background: #c6c6c6;
  color: #ffffff;
  border: none;
}

.disabledownload_adminopen {
  background: #dc143c;
  color: #ffffff;
  border: none;
}

.markbadge {
  padding: 4px 8px;
  position: static;
  height: 22px;
  top: 0px;
  background: #3b4a68;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
// **************memeber styles****************
.header_table_members {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 24px;
  position: absolute;
  width: 1440px;
  height: 53px;
  left: 0px;
  top: 198px;
  color: #21598a !important;
  background: #d4dee8;
}
.members_subheader_blue_box {
  position: static;
  min-width: 800px;
  width: auto;
  height: auto;
  left: 16px;
  top: 55px;
  background: #f8f8f8;
  border: 1px solid #d9e1e9;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0px 10px 10px 10px;
}
.font_members_panel {
  display: flex;
  flex-direction: column;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-left: 5px;
  /* identical to box height */
  color: #000000;
}
.showusersheadopt:nth-child(odd) {
  background-color: #f6f8fa;
}
.showusersheadopt:nth-child(even) {
  background: #ededed;
}
.svgfor_sorting {
  background: url(/icons/sort-u.png) no-repeat;
  top: 8px;
}
.sortingsvg {
  font-size: 16px;
  height: 10px;
  min-width: 7px;
  width: auto;
}
.font_for_show_more {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #21598a;
}
.members_header_table {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 12px 24px 12px;
  margin-top: 12px;
  height: 40px;
  background: #d4dee8;
}

.companyNamewidth {
  width: 20.1%;
  position: static;
  height: 19px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  /* Text Dark Blue Gray */
  color: #343c42;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}
.srNowidth {
  width: 6.4%;
  position: static;
  height: 19px;
  left: 0px;
  top: 0px;
  padding: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  /* Text Dark Blue Gray */
  color: #343c42;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}
.DateandTimeWidth {
  // min-width:  82px;
  // width: auto;
  min-width: 750px;
  width: auto;
  position: static;
  height: 19px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  /* Text Dark Blue Gray */
  color: #343c42;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}
.actionwidth {
  position: static;
  height: 19px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  /* Text Dark Blue Gray */
  color: #343c42;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}
.members_Header_blue {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 24px;

  position: absolute;
  min-width: 1440px;
  width: auto;
  height: 53px;
  left: 0px;
  top: 240px;
  background: #d4dee8;
}
.bg_members {
  background-color: red;
}
.removeBorder {
  border-top: hidden !important;
}
.box_radius_for_table {
  position: static;
  width: 1385px;
  height: 220px;
  left: 16px;
  top: 55px;
  background: #f8f8f8;
  border: 1px solid #d9e1e9;
  box-sizing: border-box;
  border-radius: 4px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 12px 0px;
}

.onBoarding_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  //height:100vh;
  // background-color: rgb(173, 216, 230, 0.5);
}

.user_onboarding,
.user-edit,
.confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 504px;
  padding: 24px;
  //max-height: 490px;
  min-height: 490px;
  border-radius: 4px;
  box-shadow: 0px 20px 50px rgb(0 0 0 / 5%);
  background: #ffffff;
  position: relative;
}

.confirmation {
  max-height: 700px;
}

.user_onboarding .msg {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #343c42;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 25px;
}

.user_onboarding .title {
  position: static;
  //font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  color: #21598a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 8px;
}

.user_onboarding .passwordIcon {
  margin-bottom: 17px;
}

.user_onboarding .passErr {
  margin-bottom: 16px;
}

.confirmation .title {
  position: static;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  color: #21598a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 8px;
}

.user_onboarding .welcome {
  position: static;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #343c42;
  flex: none;
  flex-grow: 0;
  margin-bottom: 30px;
}

.confirmation .welcome {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #343c42;
  flex-grow: 0;
  margin-bottom: 30px;
}

.user_onboarding button,
.confirmation button,
.user-edit button {
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: static;
  width: 456px;
  height: 43px;
  left: 0px;
  top: 264.75px;
  border-radius: 4px;
  flex-grow: 0;
  margin: 2px 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.termsText {
  width: 450px;
  padding: 10px;
  box-sizing: border-box;
  //max-height: 97%;
  overflow: auto;
  border: 1px solid #21598a;
  margin-bottom: 10px;
}

.confirmation .icon,
.user_onboarding .icon,
.user-edit .icon {
  position: relative;
  left: 5px;
  top: 0px;
}

.user-edit .title {
  position: static;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  color: #21598a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 8px;
}

.line {
  width: 443px;
  height: 0px;

  border: 1px solid #f7f7f7;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 15px;
}

.user-edit h3 {
  width: 436px;
  height: 19px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #343c42;
  flex: none;
  order: 0;
  margin-bottom: 16px;
  flex-grow: 1;
}

.user-editInput label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  min-width: 60px;
  color: #343c42;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.user-editInput input {
  width: 250px;
  height: 30px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #21598a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 10px;
  position: relative;
  bottom: 5px;
}

.user-editInput select {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px;
  gap: 10px;
  width: 175px;
  height: 28px;
  background: #f0f0f0;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  position: relative;
  bottom: 5px;
  color: black !important;
}

.databaseHade_table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 24px;
  margin-top: 10px;
  width: 100%;
  height: 40px;
  background: #d4dee8;
}
.batabase_headbase {
  padding: 12px;
  position: absolute;
  min-width: 100%;
  width: auto;
  padding: 20px;
  height: 51px;
  top: 258px;
  margin-top: 10px;
}
.dataBase_panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  // position: static;
  width: 98.7%;
  height: 59px;
  left: 0px;
  // top: 500px;

  background: #ffffff;
  border: 1.5px solid #d9e1e9;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(48, 61, 72, 0.1);
  border-radius: 4px;
  margin-left: 10px !important;
  /* Inside auto layout */
  margin-top: 8px;
  flex: none;
  order: 2;
  flex-grow: 0;
  // margin: 12px 0px;
}
.by_color {
  position: static;
  min-width: 225px;
  width: auto;
  height: 19px;
  left: 0px;
  top: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #323232;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}
.iconforevents {
  display: flex;
  padding-top: 2px;
}
.primary_outline_btn {
  padding: 10px 24px;
  height: 47px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  margin-right: 10px;
  color: #cf5a5a;
  border-radius: 4px;
  border: 1px solid #cf5a5a;
  background-color: transparent;
}
.form_label_style {
  position: static;
  min-width: 50px;
  width: auto;
  height: 19px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #343c42;
  line-height: 150%;
  border-radius: 4px;
  border: none;
}

.form_output_color {
  position: static;
  min-width: 107px;
  width: auto;
  height: 19px;
  left: 0px;
  top: 25px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Alliance Primary */

  color: #21598a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 6px 0px;
}
.credit_ref_font {
  position: static;
  min-width: 138px;
  width: auto;
  height: 19px;
  left: 10px;
  top: 30px;
  margin-top: 30px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.linespacedatabase_1 {
  padding: none !important;
}
.title_box2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.members_subheader_blue_box2 {
  position: static;
  min-width: 800px;
  width: auto;
  height: auto;
  left: 16px;
  top: 55px;
  background: #f8f8f8;
  border: 1px solid #d9e1e9;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 10px;
}
.dataBaseouterbox {
  padding: "5px";
  margin-bottom: "0px";
  margin-left: "5px";
  margin-right: "5px";
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(48, 61, 72, 0.1);
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.footer_dates_box {
  width: 99%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.event_name_date_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.eventname_box {
  width: 48%;
}
.event_dt_time_box {
  width: 48%;
}

.creditReports_download_btn_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.create_grp_btn_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.create_newevent_btn_box {
  width: 100%;
}

.creditReports_download_btn_box p {
  font-size: 14px;
}

// box-shadow: 0px 0px 4px rgba(48, 61, 72, 0.1);
// border-radius: 4px;
// }
.nopadding {
  padding-right: 0 !important;
}
.create_new_cat_font {
  position: static;
  width: 114px;
  height: 19px;
  left: 98px;
  top: 12px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}
.bg_input_red {
  background-color: brown;
}
.textEvent {
  padding-bottom: 50px;
}

.event_report_name_ongoing {
  padding-top: 2px;
}

.groups_all_topics {
  position: static;

  top: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  color: #343c42;

  padding: 40px;
}
.alltopicsGroups {
  display: flex;
  padding-top: 20px;
  padding-left: 40px;
}

.groupsTopic {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #343c42;
}

.icondiscussion {
  display: flex;

  padding-top: 20px;
}

.backgroundcreditform {
  background: #f4f4f4;
}

.alert_container_box {
  width: 79%;
  background: #f4f4f4;
  overflow-y: auto;
  overflow-x: hidden;

  height: calc(100vh - 198px);
}

.head_names {
  color: #21598a;
}
.upperdiv_subjects {
  width: 100%;
}
.discussion_credit_page_post {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  position: static;
  width: 100%;
  min-height: 78px;
  height: auto;
  left: 0px;
  top: 0px;
  list-style-type: none;
  background: #ffffff;
  border: 1.25px solid #dadada;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.coll_panel_members {
  width: 100%;
  padding: 0px;
  // margin-bottom: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(48, 61, 72, 0.1);
  border-radius: 4px;
  border: 1.5px solid #d9e1e9;
}

.table-border-radius {
  border-radius: 4px;
}
.filters_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.info_from_filters_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.info_from_filters_box div {
  display: flex;
  flex-direction: row;
  min-width: 60px;
}

.credit_ref_box {
  width: 50%;
}

.nameofevent_event_details {
  position: static;
  min-width: 450px;
  height: 21px;
  left: 10px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #343c42;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px;
}

.event_date_st_all_events {
  position: static;
  height: 21px;
  left: 0px;
  top: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #343c42;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 14px;
}

.search_box {
  margin: 0px 0px 0px -9px;
}

.bread_crumb_align ul {
  margin-left: 37px !important;
}

.srch_status_align_box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.select_date_event {
  display: inline-block;
  width: initial;
  margin: 0px -16px 0px 0px;
}

.askbtn {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.ext_link_app {
  font-size: 16px;
  border: 1px solid black;
  text-decoration: none;
  display: block;
  width: fit-content;
  background-color: #0f385b;
  border-radius: 4px;
  color: whitesmoke;
  padding: 2px 6px;
}
.ext_link_app:hover {
  color: white;
  text-decoration: none;
}

.coll_panel_members2 {
  padding: 0px;
  margin-bottom: 8px;
  margin-left: 5px;
  margin-right: 5px;
  // width: 100%;
  background: #ffffff;
  border-radius: 4px;
  border: 1.5px solid #d9e1e9;
  background: #ffffff;
  border: 1.5px solid #d9e1e9;
  box-shadow: 0px 0px 4px rgba(48, 61, 72, 0.1);
  border-radius: 4px;
}
.database_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px, 24px, 10px, 24px;
}
.publishing_date {
  position: static;
  min-width: 89px;
  width: auto;
  height: 19px;
  left: 0px;
  top: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Text Dark Blue Gray */

  color: #343c42;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}
.info_type_database {
  position: static;
  min-width: 63px;
  width: auto;
  height: 19px;
  left: 0px;
  top: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Text Dark Blue Gray */

  color: #343c42;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}
.form_database {
  position: static;
  min-width: 38px;
  width: auto;
  height: 19px;
  left: 0px;
  top: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Text Dark Blue Gray */

  color: #343c42;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}
.subject_name {
  position: static;
  min-width: 98px;
  width: auto;
  height: 19px;
  left: 0px;
  top: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Text Dark Blue Gray */

  color: #343c42;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}
.asked_cred_ref {
  /* Asked Credit References */

  position: static;
  min-width: 199px;
  width: auto;
  height: 21px;
  left: 29.94px;
  top: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  /* Text Dark Blue Gray */

  color: #343c42;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}
.asked_for_credit_red_logo {
  position: static;
  height: 21px;
  left: 29.94px;
  top: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  /* Text Dark Blue Gray */

  color: #343c42;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 6px;
}
.crediticon {
  position: static;
  left: 0%;
  right: 91.29%;
  top: 0%;
  bottom: 4.76%;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px 0px 2px;
  font-size: 18px;
}

.ask_cred_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  position: static;
  width: 202px;
  height: 40px;
  left: 871px;
  top: 0px;

  /* Alliance Blue */

  background: #21598a;
  border-radius: 4px;
  border: none;
  /* Inside auto layout */
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}
.CreditTopic {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.colorOfSubject {
  position: static;
  width: 291px;
  height: 19px;
  left: 16px;
  top: 16px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  /* Text Dark Blue Gray */

  color: #000000;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}
.title_Cred_box {
  width: 1049px;
  height: 14px;
  left: 0px;
  top: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  /* Text Dark Blue Gray */

  color: #343c42;
}
.discription_cred {
  // position: static;

  width: 100%;
  min-height: 20px;
  left: 0px;
  top: 20px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  /* Text Dark Blue Gray */

  color: #343c42;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 0px;
}
.date_time_cred {
  // min-width: 70px;
  // width: auto;
  // height: 12px;
  // left: 0px;
  // top: 0px;

  padding-left: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  /* Secondary Text Gray */

  color: #747474;

  /* Inside auto layout */

  // flex: none;
  // order: 0;
  // flex-grow: 0;
  // margin: 0px 0px;
}
.Hr_credit_form {
  border: 2px solid #f0f0f0;
  margin: 10px 0px;
}

.ant-form-item {
  margin-bottom: 10px; // default is 16px
}
.font_styles_new_cred_forms {
  //   width: 178px;
  // height: 16px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #323232;

  // /* Inside auto layout */

  // flex: none;
  // order: 0;
  // flex-grow: 0;
}
.title_credit_forms {
  min-width: 106px;
  width: auto;
  height: 19px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #21598a;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.members_panel_list_box {
  padding: 0px 12px;
}

.fill_credit_btn_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.pagenation_style {
  position: static;
}

.full_event_name_creditpost {
  font-size: 20px;
  line-height: 20px;
  padding-top: 5px;
}

.header_title_box {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.header_title_box div:nth-child(1) {
  min-width: 120px;
  max-width: 120px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.header_title_box div:nth-child(2) {
  min-width: 208px;
  max-width: 208px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.header_title_box div:nth-child(3) {
  min-width: 140px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.members_header_table > div {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.border_bottom {
  padding-bottom: 4px;
  border-bottom: 2px solid #f0f0f0;
}

.sub_details_title {
  padding-top: 10px;
  margin-right: 25px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #21598a;
}

.total_owing_field {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  padding: 12px;
  margin: 0px !important;
}
.total_owing_field > div {
  color: #323232;
}

.total_owing_field_value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #21598a !important;
}

.border_bottom_credit {
  padding-bottom: 8px;
  border-bottom: 2px solid #f0f0f0;
}

.input_field_bg_color {
  background: #fafafa !important;
}
.credit_sbt_btn {
  padding: 12px 24px;
  height: 43px;
  border: none;
  color: #ffffff;
  background: #21598a;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  flex: none;
  order: 3;
  flex-grow: 0;
  margin: 0px 0px;
}

.group_database_table_head {
  width: 100%;
}

.group_database_tableheader_titles {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #343c42;
}

.group_database_tableheader_titles div:nth-child(1) {
  min-width: 169px;
  max-width: 170px;
}

.group_database_tableheader_titles div:nth-child(2) {
  min-width: 150px;
  max-width: 150px;
}
.group_database_tableheader_titles div:nth-child(3) {
  min-width: 125px;
  max-width: 125px;
}

.group_database_tableheader_titles div:nth-child(4) {
  min-width: 145px;
}

.group_dtbase_title_box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 40%;
  height: 100%;
}

.group_dtbase_title_box div:nth-child(1) {
  min-width: 172px;
  max-width: 172px;
}

.group_dtbase_title_box div:nth-child(2) {
  min-width: 149px;
  max-width: 149px;
}
.group_dtbase_title_box div:nth-child(3) {
  min-width: 127px;
  max-width: 127px;
}
.group_dtbase_title_box div:nth-child(4) {
  min-width: 300px;
}

.edit_icon_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.topic_edit_btn {
  padding: 6px 10px 0px 0px;
  font-size: 27px;
}

.topic_save_btn_box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.react_back_arrow_icon_ask_credit {
  cursor: pointer;
  color: #21598a;
  font-size: 27px;
}
.alertpagenation {
  //position: fixed;
  left: 50%;
  top: 90%;
}

.react_icons_box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 8px;
}
.edit_chat_icon {
  cursor: pointer;
  margin: 0px 7px 0px 0px;
}

.delete_chat_icon {
  cursor: pointer;
  margin: 0px 7px 0px 0px;
}

.uploaded_files_box {
  display: flex;
  flex-direction: row;
}

.uploaded_files_box > div {
  font-weight: 500;
  color: #21598a;
}
.delete_icon_file {
  cursor: pointer;
  padding-left: 7px;
}

.releasereports_disable {
  background: #c6c6c6;
  pointer-events: none;
}

.edit_credit_ref_btn_admin {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 12px 32px;
  gap: 10px;
  height: 43px;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 0;
  margin-right: 12px;
  flex-grow: 0;
}

.edit_credit_ref_btn button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 10px 24px;
  height: 43px;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  margin: 0px 15px 10px 0px;
}

.dollar_prefix {
  position: absolute;
  z-index: 1;
  color: rgb(44, 44, 81);
  line-height: 27px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 0 0 3px;
}

.dollar_prefix_subjects_admin {
  position: absolute;
  z-index: 1;
  color: rgb(44, 44, 81);
  line-height: 27px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 0 0 3px;
  margin: 30px 0px 0px 0px;
}

.dollar_prefix_subjects_admin_edit_credit {
  position: absolute;
  z-index: 1;
  color: rgb(44, 44, 81);
  line-height: 27px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 0 0 3px;
  margin: 0px;
}

.event_reports_goto_event_details_page {
  text-decoration: none;
  background: #ffffff;
  border: 2px solid #21598a;
  color: #21598a;
}

.event_reports_goto_event_details_page a {
  height: 100%;
  color: #21598a;
}

.event_reports_goto_event_details_page:hover {
  background: #e6eeff;
  transition: 0.2s;
  color: #21598a;
}

.event_reports_goto_event_details_page a:hover {
  text-decoration: none;
  color: #ffffff;
}

.last_msg {
  display: flex;
  align-items: flex-end;
  padding-left: 12px;

  line-height: 15px;
  color: #747474;
  padding-top: 5px;
}

.last_msg_username {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding-right: 7px;
  color: #747474;
}
.last_msg_header {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  padding-right: 7px;
  color: #747474;
}

.alert_type_sty {
  color: #21598a;
  padding: 10px 0px 0px 7px;
  font-weight: 500;
}

.style_username {
  color: #21598a;
}
.create_new_alert_header {
  min-width: 131px;
  width: auto;
  height: 21px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.font_of_alert_modal {
  //   min-width: 61px;
  //   width: auto;
  // height: 16px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #323232;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.create_alert_btn_modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  height: 43px;
  color: #ffffff;
  border: none;
  background: #21598a;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.modal-footer {
  padding-left: 19px !important;
}
// .modal-header{
//   padding-left: 25px !important;
//   padding-right: 200px !important;
// }
.Alert_container_new_box {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 12px;

  position: relative;
  width: 1180px;
  height: auto;

  background: #ffffff;
  /* Border Gray */

  border: 1.5px solid #dadada;
  border-radius: 4px;
}
.font_alert_col_box {
  font-size: 12px;
  padding-left: 12px;
  font-weight: 500 !important;
  font-family: "Roboto";
  font-style: normal;
  line-height: 14px;
  color: #21598a;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.font_alert_col_box_first {
  font-size: 12px;
  // padding-left: 12px;
  font-weight: 500 !important;
  font-family: "Roboto";
  font-style: normal;
  line-height: 14px;
  color: #21598a;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.font_col_box_output {
  // min-width: 151px;
  // width: auto;
  height: 16px;
  padding-left: 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  /* Text Dark Blue Gray */

  color: #343c42;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.credit_ref_box_new {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 95%;
  height: 122px;

  background: #ffffff;
  border: 1.5px solid #e0e0e0;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.subject_box_2 {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 95%;
  height: 123px;

  background: #ffffff;
  border: 1.5px solid #e0e0e0;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.nomargin {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.box_container {
  width: 100%;
}
.credit_ref_font {
  width: 128px;
  height: 19px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Alliance Primary */

  color: #21598a;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.credit_ref_post_box {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 95%;
  height: 150px;

  background: #ffffff;
  border: 1.5px solid #e0e0e0;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.table-data-label {
  font-size: 12px;
  color: #21598a;
  font-weight: 500;
  display: block;
}

.data-table {
  background: #ffffff;
  border: 1.5px solid #e0e0e0;
  border-radius: 4px !important;

  border-collapse: separate;
  width: 100%;

  box-sizing: border-box;
  min-width: 720px;
  margin-bottom: 12px;
}

td {
  padding: 6px;
}

.table-data-value {
  font-size: 14px;
  font-weight: 500;
}

.data-table-row {
  background-color: #f6f8fa;
  min-height: 72px;
}
.credit_ref_word_font {
  min-width: 128px;
  width: auto;
  height: 19px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Alliance Primary */

  color: #21598a;
  margin-bottom: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.edit_btn_value_show {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  height: 40px;

  /* Alliance Blue */
  margin-right: 10px;
  background: #21598a;
  border-radius: 4px;
  border: none;
  /* Inside auto layout */
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 0px;
  margin-bottom: 10px;
}
.data_table_for_out_elm {
  border: 1.5px solid #e0e0e0;
  border-radius: 5px !important;
  width: 100%;
  min-height: 50px;
  height: auto;
  padding: 0;
  box-sizing: border-box;
  // min-width: 720px;
  margin-bottom: 12px;
  // padding-left: 12px !important;
  border-top: none;
  border-collapse: separate;
}
.data_table_groupdbal {
  border: 1.5px solid #e0e0e0;
  border-radius: 4px;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  min-height: 100px !important;
  height: auto !important;
  // min-width: 720px;
  margin-bottom: 12px;
  // padding-left: 12px !important;
  border-collapse: separate;
}
.show_details_container2 {
  position: static;
  width: 100%;
  height: auto;
  left: 0px;
  top: 0px;
  // background: #F4F6F9;
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  padding: 10px;
  // margin: 1px 15px 0px 0px;
  // margin-left: 20px;
}
.Alert_container2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  position: static;
  width: 100%;
  height: auto;
  left: 16px;
  top: 75px;
  // background: #F4F6F9;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px;
}
.data_table_groupdbal2 {
  border: 1.5px solid #e0e0e0;
  border-radius: 4px;
  width: 100%;
  min-height: 100px;
  height: auto;
  padding: 0;
  box-sizing: border-box;

  // min-width: 720px;
  margin-bottom: 12px;
  // padding-left: 12px !important;
  border-collapse: separate;
}
.data_table_for_out_elm2 {
  border: 1.5px solid #e0e0e0;
  border-radius: 4px;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  min-height: 50px;
  height: auto;
  // min-width: 720px;
  margin-bottom: 12px;
  // padding-left: 12px !important;
  border-collapse: separate;
  border-top: none;
}
.show_details_container3 {
  position: static;
  width: 100%;
  height: auto;
  left: 0px;
  top: 0px;
  // background: #F4F6F9;
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  padding: 10px;
}
.data-table2 {
  background: #ffffff;
  border: 1.5px solid #e0e0e0;
  border-radius: 4px !important;

  border-collapse: separate;
  width: 100%;
  min-height: 100px;
  height: auto;
  box-sizing: border-box;
  // min-width: 720px;
  margin-bottom: 12px;
}
.CreditRef_bg {
  background: #f4f4f4;
  width: 79%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.data_table_groupdbal3 {
  border: 1.5px solid #e0e0e0;
  border-radius: 4px;
  width: 100%;
  padding: 0;
  min-height: 100px;
  height: auto;
  box-sizing: border-box;
  // min-width: 720px;
  margin-bottom: 12px;
  // padding-left: 12px !important;
  border-collapse: separate;
}
.data_table_groupdbal3 {
  border: 1.5px solid #e0e0e0;
  border-radius: 4px;
  width: 100%;
  padding: 0;
  min-height: 100px;
  height: auto;
  box-sizing: border-box;
  // min-width: 720px;
  margin-bottom: 12px;
  // padding-left: 12px !important;
  border-collapse: separate;
}
.data_table_for_out_elm3 {
  border: 1.5px solid #e0e0e0;
  border-radius: 5px !important;
  width: 100%;
  padding: 0;
  min-height: 50px;
  height: auto;
  box-sizing: border-box;
  // min-width: 720px;
  margin-bottom: 12px;
  // padding-left: 12px !important;
  border-top: none;
  border-collapse: separate;
}

.credit_fill_lbl_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-left: 5px;
  line-height: 19px;
  color: #21598a;
}

.submit_sub_btn_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

// .animateSlide {
//     animation: slideDown 0.5s linear;
// }

// @keyframes slideDown {
//   from {transform: translateY(-25px)}
//   to {transform: translateY(0px)}

// }

.province_select_tag {
  padding: 8px 12px 8px 10px;
  position: static;
  width: 100%;
  height: 40px;
  left: 0px;
  top: 25px;
  background: #fafafa !important;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px;
}

.disable_submit {
  background: #c6c6c6;
  color: #ffffff;
  pointer-events: none;
  cursor: not-allowed;
}
.sidebar_menu_discussion_inactive span {
  color: #21598a;
}
.editorClassName {
  padding: 0px 0px 0px 7px;
  height: 100%;
  background: #ffffff;
}
.rdw-editor-toolbar {
  background: transparent;
}
.alert_no_pagenation_text {
  position: absolute;
  top: 74vh;
  width: 78%;
}
.addbtn:hover {
  background: #1a4469;
  color: #ffffff;
}
.create_btn_modal:hover {
  background: #1a4469;
  color: #ffffff;
}
.create_new_event_btn:hover {
  background: #1a4469;
  color: #ffffff;
}
.group_showmoredetails_btn:hover {
  background: #1a4469;
  color: #ffffff;
}
.edit_event_new_btn:hover {
  background: #1a4469;
  color: #ffffff;
}
.create_save_event_btn:hover {
  background: #1a4469;
  color: #ffffff;
}
.group_showsubj_btn:hover {
  background: #1a4469;
  color: #ffffff;
  text-decoration: none;
}
.group_submitsubj_btn:hover {
  background: #1a4469;
  color: #ffffff;
}
.show_det_btn:hover {
  background: #1a4469;
  color: #ffffff;
  text-decoration: none;
}
.releasereports:hover {
  background: #1a4469;
  color: #ffffff;
}
.group_showmore_btn:hover {
  background: #1a4469;
  color: #ffffff;
}
.group_showrep_progress_btn:hover {
  background: #1a4469;
  color: #ffffff;
}
.create_new_post_discussion_new:hover {
  background: #1a4469;
  color: #ffffff;
}
.show_more_btn:hover {
  background: #1a4469;
  color: #ffffff;
}
.send_btn_chat:hover {
  background: #1a4469;
  color: #ffffff;
}
.Ask_for_credit_ref_btn:hover {
  background: #1a4469;
  color: #ffffff;
}
.create_new_post_discussion:hover {
  background: #1a4469;
  color: #ffffff;
}
.create_alert_btn_modal:hover {
  background: #1a4469;
  color: #ffffff;
}
.edit_credit_ref_btn_admin:hover {
  background: #1a4469;
  color: #ffffff;
}
.add_subject_btn:hover {
  background: #1a4469;
  color: #ffffff;
}
.edit_btn_value_show:hover {
  background: #1a4469;
  color: #ffffff;
}
.buton_submit:hover {
  background: #1a4469;
  color: #ffffff;
}
.primary_btn:hover {
  background: #1a4469;
  color: #ffffff;
}
.primary_outline_btn:hover {
  background: #cf5a5a27;
}

.edit_chat_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  height: 43px;
  background: #21598a;
  color: #ffffff;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.edit_chat_btn:hover {
  background: #1a4469;
  color: #ffffff;
}
.cancel_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  height: 43px;
  background: transparent;
  color: #cf5a5a;
  border-radius: 4px;
  border: 1px solid #cf5a5a;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.cancel_btn:hover {
  background: #cf5a5a27;
  color: #cf5a5a;
}

.order-New-Report_imageCase__2z19N {
  border: 3px solid #21598a !important;
  figure {
    figcaption {
      color: #21598a !important;
      font-weight: 600;
    }
  }
}

.order-New-Report_stepLink__3O0va {
  color: #21598a !important ;
}
.order-New-Report_stepBullet__G-0Nm {
  background-color: #21598a !important;
}

.new_messages_alert {
  background: #5185ed1f;
}
.cutom_alliance_badge {
  background: #f53535;
  margin: 0px 0px 0px 10px;
  font-size: 13px;
}
.alliance_primary_outline {
  padding: 12px 24px;
  height: 43px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  color: #21598a;
  border-radius: 4px;
  border: 1px solid #21598a;
  background-color: transparent;
}

.create_new_catgorie_credit_post:hover {
  background: #1a4469;
  color: #ffffff;
}

.bankLinkInactive {
  color: red;
  position: relative;
  right: 180px;
  top: -3px;
}

.display_none_supplier {
  display: none;
}

.bank_options {
  color: #ffffff;
  background: #21598a;
  border-radius: 4px;
  // font-size: 16px;
  padding: 0px 10px;
  font-family: "Roboto";
  font-style: normal;
  width: max-content;
  border: none;
}

.reset_password_page {
  box-shadow: 0 0 18px 0 #b8b8b8;
  padding: 50px;
}

.custom_sig_modal {
  width: 100%;
  height: 100vh;
  background: #0000003f;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom_sig_modal_bdy {
  position: relative;
  background: #ffffff;
  width: 50%;
  min-height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 3px 3px 4px #00000015;
}

.close_icon_sig {
  position: absolute;
  top: 7px;
  right: 10px;
  padding: 5px;
  cursor: pointer;
}

.sig_pad_style {
  border: 1px solid #e0e0e0;
}
.sig_pad_btn_box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.sig_pad_btn_box button {
  margin: 6px;
}

.bank_disable_select__control {
  cursor: not-allowed !important;
  pointer-events: none !important;
  background-color: #f8f8f8 !important;
}

.yup_error_border {
  border: 1px solid red;
  border-radius: 4px;
}

.order-New-Report_stepBullet__QPwZE {
  background-color: #21598a !important;
}
.order-New-Report_stepLink__0k_EG {
  color: #21598a !important ;
}
.show_demo {
  width: 15px;
  height: 15px;
  color: #21598a;
  accent-color: red;
  background-color: red;
}

.tokenExpiry_wrap {
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tokenExpiry {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.check_box_not_avail {
  padding-left: 6px;
}
.check_box_not_avail input[type="checkbox"] {
  margin-right: 6px;
}

.headetails_para {
  margin-top: 18px;
}

.reset_password_container {
  z-index: 9999 !important;
}

.title_grp_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.title_grp_box ul {
  float: left;
}
.titles_box {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 34px;
}

.category_name {
  height: 21px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #343c42;
  padding-top: 2px;
  margin: 0px;
}

.not_event_check {
  padding: 0px 0px 0px 25px;
}

.bot_buttons_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.display_input {
  outline: none;
  font-weight: bold;
  font-size: 16px !important;
  border: none;
  background: #fff !important;
  cursor: auto !important;
  appearance: none;
  pointer-events: none !important;
  width: 250px;
}

.multiple input {
  position: relative;
  top: 5px;
}
.multiple input:checked {
  position: relative;
  top: 0px;
}

.subcategory_react_icon {
  font-size: 24px;
  color: #21598a;
}

.noCheck_error {
  font-size: 14px;
  color: rgb(221, 9, 9);
  font-weight: 600;
}

.reports_download_btns_box {
  display: flex;
  flex-direction: row;
}

.no_download_reports_btn_style {
  background: #c6c6c6;
  pointer-events: none;
}

.font_grpdatabase_col_box {
  font-size: 14px;
  padding: 5px 5px 5px 0px;
  font-weight: 500 !important;
  font-family: "Roboto";
  font-style: normal;
  line-height: 14px;
  color: #21598a;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.details_box {
  display: flex;
  flex-direction: row;
  padding-left: 12px;
}

.details_box p {
  margin: 0px 7px 0px 0px;
  color: #000000;
  font-size: 12px;
}

.dispatch_table {
  position: fixed;
  margin-top: -8px;
  background-color: white;
  min-height: 120px;
  max-height: 220px;
  overflow-y: scroll;
  width: 100%;
  z-index: 200;
}

.dispatch_list {
  position: relative;
  margin-top: 220px;
}

.team_style {
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  width: fit-content;
  padding: 2px 10px;
}

.user_list_box {
  display: flex;
  flex-direction: column;
  border: 1px solid #0000002f;
  border-radius: 5px;
  height: 180px;
  max-height: 250px;
  overflow: auto;
  padding: 10px;
}

.single_user_box label {
  width: 75%;
}

.teams_bg {
  position: static;
  width: auto;
  min-height: 21px;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  border-radius: 5px;
  /* identical to box height */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 6px;
  margin: 0px 10px;
}

.edit_and_delete_team {
  color: #000;
  background: #e4e4e4;
  border-radius: 4px;
  font-size: 16px;
  padding: 8px 16px;
  font-family: "Roboto";
  font-style: normal;
  width: max-content;
  border: none;
}
.fill_bank_form_link {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.submit_btn_box {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.groups_bank_form_container {
  margin: 0px 40px;
  padding: 15px;
}

.edit_msg_input {
  overflow: hidden;
  padding: 7px;
}

.details_box_fill_credit {
  display: flex;
  flex-direction: row;
}

.details_box_fill_credit p {
  margin: 0px 7px 0px 0px;
  color: #000000;
  font-size: 12px;
}

.fill_credit_collapse_panel_container {
  padding-left: 10px;
}

.admin_edit_btn_box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.font_members_panel_address {
  display: flex;
  min-width: 150px;
  flex-direction: column;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-left: 5px;
  color: #000000;
}

.subject_list_dwnd_btn {
  height: 43px;
  min-width: 155px;
  border-radius: 4px;
  text-align: center;
  margin: 15px;
  align-items: center;
  padding: 4px 8px;
}

.subject_list_btn {
  background: #21598a;
  color: #ffffff;
  border: none;
}

.no_account_btn {
  border-radius: 4px;
  text-align: center;
  margin: 15px;
  font-size: 12px;
  align-items: center;
  padding: 4px 8px;
  background: #cf5a5a;
  color: #ffffff;
  border: none;
}

.subject_details_temp {
  font-size: 14px;
  padding-left: 12px;
  font-weight: 500 !important;
  font-family: "Roboto";
  font-style: normal;
  line-height: 14px;
  color: #21598a;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.subject_details_para {
  font-size: 14px;
  padding: 4px;
}

.subject_checkboxes {
  display: flex;
  flex-direction: row;
}

// credit_report icon status
.credit_reports_icon_wrapper {
  display: grid;
  grid-gap: 0.5rem;
  width: max-content;

  .credit_report_icon_box {
    .credit_report_icon_category {
      .credit_report_icon_category_name {
        font-weight: bold;
      }

      .credit_report_icon_icons_wrapper_5 {
        display: grid;
        grid-template-columns: repeat(6, max-content);
        grid-auto-rows: auto;
        grid-gap: 0.75rem;

        .credit_report_icon_container {
          outline: 2px solid lightgrey;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          grid-gap: 0.75rem;
          margin-top: 0.5rem;
          cursor: pointer;
          transition: 0.2s;
          padding: 0.5rem;
          //height: 5rem;
          min-width: 75px;

          .credit_report_icon_image {
            height: 36px;
            width: 36px;
          }
        }
        .credit_report_selected_icon {
          @extend .credit_report_icon_container;
          //  outline: 2px solid #21598a;
          outline: 2px solid #055397;
          background-color: lightcyan;
        }
      }

      .credit_report_icon_icons_wrapper_6 {
        display: grid;
        grid-template-columns: repeat(6, max-content);
        grid-auto-rows: auto;
        grid-gap: 0.75rem;

        .credit_report_icon_container {
          outline: 2px solid lightgrey;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          grid-gap: 0.75rem;
          margin-top: 0.5rem;
          cursor: pointer;
          transition: 0.2s;
          padding: 0.5rem;
          //height: 5rem;
          min-width: 75px;

          .credit_report_icon_image {
            height: 36px;
            width: 36px;
          }
        }
        .credit_report_selected_icon {
          @extend .credit_report_icon_container;
          //  outline: 2px solid #21598a;
          outline: 2px solid #055397;
          background-color: lightcyan;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .credit_report_icon_icons_wrapper {
    grid-template-columns: repeat(3, max-content) !important;
  }
}

.credit_reports_icons_buttons_wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}
.credit_report_icon_name {
  max-width: 120px;
  text-align: center;
}

.email_fax_popup_wrapper {
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 8px;
  align-items: center;
  padding: 0 25px;

  div {
    display: grid;
    grid-template-columns: 75px 1fr;
    grid-column-gap: 10px;
    align-items: center;
    white-space: nowrap;
  }
  .fax_files_wrapper {
    .fax_files_content {
      margin: 10px 30px;
      div {
        margin: 5px 0;
      }
    }
  }
}
.fax_logs_wrapper {
  padding: 0 25px;
  .fax_logs_header {
    display: grid;
    grid-template-columns: 100px 80px 80px 150px 1fr;
    grid-column-gap: 15px;
    div {
      font-weight: bold;
    }
  }
  .fax_logs_content_wrapper {
    margin-top: 0.75rem;
    .fax_logs_values {
      display: grid;
      grid-template-columns: 100px 80px 80px 150px 1fr;
      grid-gap: 15px;
      margin-bottom: 10px;
    }
  }
}
#fax_logs tr:nth-child(even) {
  background-color: #f2f2f2;
}
.fax_popup_error {
  display: grid;
  place-items: center;
  font-weight: bold;
  color: red;
  margin-bottom: 50px;
}
.fax_logs_wrapper {
  padding: 0 25px;
  .fax_logs_header {
    display: grid;
    grid-template-columns: 100px 80px 80px 150px 1fr;
    grid-column-gap: 15px;
    div {
      font-weight: bold;
    }
  }
  .fax_logs_content_wrapper {
    margin-top: 0.75rem;
    .fax_logs_values {
      display: grid;
      grid-template-columns: 100px 80px 80px 150px 1fr;
      grid-gap: 15px;
      margin-bottom: 10px;
    }
  }
}
#fax_logs tr:nth-child(even) {
  background-color: #f2f2f2;
}
.fax_popup_error {
  display: grid;
  place-items: center;
  font-weight: bold;
  color: red;
  margin-bottom: 50px;
}
.email_fax_popup_wrapper_btns_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

.email_fax_error {
  color: red;
}

.credit_form_btn_wrapper {
  display: grid;
  grid-template-columns: 25rem 20rem 1fr 15rem;
  grid-column-gap: 20px;
  padding: 1rem;
}

.credit_form_wrapper {
  min-height: 100vh;
  padding: 1rem;
  background: #f6f8fa;
  min-width: 1032px;
  .credit_form_table_header {
    display: inline-flex;
    //grid-template-columns: 1fr 1fr 1.2fr 1.2fr 1fr 1fr 1.8fr 1.8fr;
    grid-gap: 12px;
    align-items: center;
    padding: 1rem;
    background: #fbfbfb;
    color: #121723;
    border-radius: 0.4rem;
    width: 100%;
    min-width: 1000px;
    div {
      &::selection {
        color: white;
        background: blue;
      }
    }
  }
  .credit_form_table {
    background: #f6f8fa;
    .credit_form_table_wrapper {
      margin-top: 1rem;
      display: inline-flex;
      //grid-template-columns: 1fr 1fr 1.2fr 1.2fr 1fr 1fr 1.8fr 1.8fr;
      grid-gap: 12px;
      align-items: center;
      background: #fff;
      padding: 1rem;
      border-radius: 0.4rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      width: 100%;
      min-width: 1000px;
      div {
        &::selection {
          color: white;
        }
      }

      .credit_form_btns {
        display: grid;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        //white-space: nowrap;
        .sbtn {
          background: rgb(230, 238, 255);
          color: #356eb1;
        }
        button {
          border: none !important;
          font-size: 12px !important;
        }
      }
    }
  }
}
.credit_form_fields_wrapper {
  .inputs_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 1.6rem;

    input {
      background: #fafafa;
      border: 1px solid #c1c1c1;
      border-radius: 4px;
      padding: 10px;
    }
  }
  .cf_checkbox_wrapper {
    display: flex;
    gap: 20px;
    margin: 15px 0;
    label {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .cr_order_companySelect {
    padding: 0;
  }
}

.credit_form_modal {
  .modal-content {
    max-width: 480px;
    padding: 40px;

    .modal-title {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #343c42;
    }
    .modal-body {
      padding: 0;
    }
    .modal-footer {
      display: flex !important;
      align-items: center;
      justify-content: flex-end;
      padding-right: 0;
      margin-top: 0.5rem;
    }
  }
}

.credit_form_report_modal {
  .modal-content {
    width: 744px;
    padding: 20px;
    .report_selection_wrapper {
      .heading_subheading_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        .sub_heading {
          font-weight: 400;
          font-size: 14px;
          color: #6d738d;
        }
      }
      .report_selection {
        label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 0.75rem;
          color: #121723;
          font-weight: 500;
          font-size: 14px;
        }
      }
      .report_selection_box_wrapper {
        // margin: 0.5rem 0 1rem 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        flex-wrap: wrap;
        border: 1px solid transparent;
        padding: 8px;
        .report_selection_box {
          background: #edf1f5;
          padding: 0.75rem 0.5rem;
          border: 0.2rem solid #dce6ef;
          border-radius: 0.4rem;
          width: max-content;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          gap: 1rem;
          span {
            white-space: nowrap;
          }
        }
        .report_selected {
          @extend .report_selection_box;
          border: 0.2rem solid #356eb1;
          background: #dfeeff;
          transition: 0.3s;
        }
      }
      .other_reports_wrapper {
        .other_report_heading {
          font-weight: 500;
          font-size: 14px;
          color: #384665;
          margin: 1rem 0;
        }
      }
    }
    .bank_options_wrapper {
      margin-bottom: 1rem;
      .location_form,
      .bank_options_list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        label {
          font-weight: 400;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 2rem;
          input {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}

.cf_status_container {
  padding: 10px 0px;
}
.cf_status {
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px;
  padding: 5px;
  border-radius: 4px;
  //color: #fff;
}

.cf_1 {
  background: #f1f1f1;
  color: #747474;
}
.cf_2 {
  background: #fff5e3;
  color: #e97a15;
}
.cf_3 {
  background: #ffe6e6;
  color: #cf5a5a;
}
.cf_4 {
  background: #ffe6e6;
  color: #cf5a5a;
}
.cf_5 {
  background: #e6ffeb;
  color: #388f46;
}
.cf_6 {
  background: #f1f1f1;
  color: #747474;
}

.bank_ctd,
.terms_accepted {
  display: flex;
  align-items: center;
  gap: 4px;
}

.cf_send_in_bulk {
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 15px;
  margin-bottom: 10px;
}

// credit details page
.credit_details_page_wrapper {
  .heading_back {
    padding: 10px 0;
    span {
      margin-right: 10px;
      color: #384665;
      svg {
        cursor: pointer;
      }
    }
  }
  padding: 20px;
  .cdp_details_header {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 7rem 7rem 8rem 8rem 6rem 8rem 15rem;
    grid-column-gap: 20px;
    //background: #e9e9e9; //old
    //padding: 24px 16px;
    //background: #f2f4f8; //new
    border-radius: 4px;
    font-weight: 400;
    .title_title {
      display: flex;
      flex-direction: column;
    }
  }
  .cdp_content_header {
    border: 1px solid #f3f5f9;
    box-shadow: 0px 2px 4px rgba(46, 48, 57, 0.1);
    border-radius: 4px;
  }
  .cdp_general_details {
    margin-bottom: 2rem;
    .one {
      background: #f4f5f8;
      border-radius: 4px;
      padding: 8px 12px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .two {
      @extend .one;
      background: #fff;
    }
  }

  .cdp_company_info {
    .cdp_group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      margin-bottom: 1.5rem;

      .cdp_section {
        .cdp_group_one {
          background: #f4f5f8;
          border: 1px solid #f8f9fb;
          border-radius: 2px;
          margin-bottom: 10px;

          .cdp_section_one {
            padding: 8px 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
    .cdp_group_3 {
      @extend .cdp_group;
      grid-template-columns: repeat(3, 1fr);
    }
    .cdp_group_one_full {
      @extend .cdp_group;
      grid-template-columns: 1fr;
      background: #f4f5f8 !important;
      padding: 8px 12px;

      .cdp_comment_title {
        font-weight: 400;
        font-size: 12px;
        color: #6d738d;
      }
    }
  }

  .cdp_bank_info {
    @extend .cdp_company_info;
    .cdp_group_one {
      background: #fff !important;
      margin-bottom: 0 !important;
      .cdp_section_one {
        &:nth-child(2n + 1) {
          background: #f4f5f8 !important;
        }
      }
    }
  }
  .cdp_supplier_info {
    @extend .cdp_bank_info;
    .cdp_supplier_wrapper {
      display: inline-block !important;
      width: calc(50% - 10px);
      &:nth-of-type(even) {
        margin-right: 10px;
        width: 50%;
      }
      .cdp_supp_title {
        color: #121723;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        padding: 8px 0;
      }
    }
    .cdp_group {
      grid-template-columns: 1fr;
    }
  }

  .cdp_key {
    color: #6d738d;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
  .cdp_value {
    color: #121723;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    .cdp_download_btn {
      padding: 4px 8px;
      background: #356eb1;
      border-radius: 2px;
      width: 64px;
      height: 20px;
      color: #fff;
      cursor: pointer;
      margin-left: 5px;
    }
  }
}

.cdp_title {
  color: #121723;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  padding: 8px 0;
}
.cdp_signature {
  padding: 20px 0;
  display: flex;
  align-items: center;
  .sign_details {
    width: 340px;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 4px;
      .key {
        color: #384665;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
      }
      .value {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #121723;
      }
    }
  }
  .cdp_signature_box {
    background: #f8f9fb;
    border: 0.5px solid #f0f2f8;
    border-radius: 4px;
    width: 338px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.cdp_owner {
  background: #ffc72c;
  border-radius: 1px;
  padding: 2px 4px;
  width: 40px;
  height: 16px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  color: #000000;
  margin-right: 5px;
}

.cal_canel_btn {
  background: #ed4e62 !important;
}

.capr_select {
  padding: 10px 0 !important;
  margin-bottom: 12px;
}
.capr_error {
  padding: 5px 0;
  font-size: 14px;
  color: #ed4e62;
  font-weight: 600;
}

.capr_account_error {
  div {
    border: 1px solid #ed4e62 !important;
  }
}
.capr_report_error {
  border: 1px solid #ed4e62 !important;
  padding: 8px;
  border-radius: 4px;
}

.cap_pagination_wrapper {
  display: grid;
  place-items: center;
  padding: 10px;
}

.bank_send_fax {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}

.resend_email_popup_wrapper {
  .modal-content {
    padding: 20px;
  }
  .modal-body {
    padding: 0;
  }
  .resend_email_content_wrapper {
    padding: 15px 0;
    .rec_title_value {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: 80px 1fr;
      grid-column-gap: 20px;

      .rec_checkbox_wrapper_content {
        display: flex;
        align-items: center;
        label {
          margin-right: 25px;
          display: flex;
          align-items: center;
          .rec_checkbox_wrapper {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.rec_error_input {
  border: 1px solid red !important;
}
.rec_error {
  font-size: 12px;
  color: red;
  margin-bottom: 10px;
}
.no-app-access {
  height: 85vh;
  place-items: center;
}

.report_consumer_wrapper {
  margin-top: 15px;
  .consumer_names_wrapper {
    display: flex;
    gap: 20px;
    label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
    }
  }
}

.liability_group {
  //border: 2px solid blue !important;
  background: #fff7f7 !important;
  .cdp_key {
    color: #6d738d !important;
  }
  .cdp_value {
    color: #ed4e62 !important;
  }
  .cdp_section_one {
    border-bottom: 0.5px solid #f7ebeb;
  }
}
.asset_group,
.other_income_group {
  background: #ecfff6 !important;
  .cdp_key {
    color: #6d738d !important;
  }
  .cdp_value {
    color: #33ad73 !important;
  }
  .cdp_section_one {
    border-bottom: 0.5px solid #f7ebeb;
  }
}
.cdp_personal_info {
  .cdp_section_one {
    border-bottom: 0.5px solid #f7ebeb;
  }
}
.cdp_lll {
  margin-bottom: 0 !important;
  &:nth-child(2n) {
    border-top: 0.5px solid #f7ebeb !important;
  }
}

.cdp_divider {
  border-bottom: 0.5px solid #f7ebeb;
}

.auto_filled_wrapper {
  margin-left: 10px;
  background: #33ad73;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  padding: 8px;
}
.health_monitoring_header {
  height: 100%;
  padding: 18px 20px;
  cursor: pointer;
  &:hover {
    background: #0f385b;
  }
}
.health_monitoring {
  color: #fff;
  font-size: 11px;
}

.select_company {
  font-weight: bold;
  margin: 0.25rem 0;
}
.select_company_text {
  margin-bottom: 0.25rem;
}

.uptodate_by {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
}
